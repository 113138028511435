import {
  FrequencyBand,
  IFiltersDropdownOptions,
  IOptions,
  IPayloadError,
  LOADING,
} from 'store/types';
import {
  HardwareRequester,
  Subscriber2Requester,
  VizRequester,
  formatError,
  formatURL,
} from 'apiUtils/api';
import {
  IDeviceDetailsForm,
  ILANConfigForm,
  IOLTDetailsForm,
  ISplitter,
  ISubscriber,
  ISubscriberDetailsForm,
  ISubscriberFormSteps,
  ISupportedEnumValues,
  IVoIP,
  IVoIPPortsSupportedResponse,
  IWANConfigForm,
  IWLANConfigForm,
  IWLANForm,
  ONBOARDING_STATE,
  SUBSCRIBER_FORM_NAMES,
  SUBSCRIBER_FORM_STEPS,
  TFormValues,
} from 'store/types/subscriber.types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_END_POINTS } from 'apiUtils/urls';
import { AxiosResponse } from 'axios';
import { IFranchisee } from 'store/types/franchisee.types';
import { IOLTListItems } from 'store/types/olt.types';
import { ISplitterDropdown } from 'store/types/splitter.types';
import { OLTOnboardingService } from 'apiUtils/services/oltOnboarding.service';
import { StepProps } from 'antd';
import { VizService } from 'apiUtils/services/vizbff.service';
import { isEmpty } from 'lodash';
import { splitCountryCodeAndPhoneNumber } from 'utils/country';

interface IOLTDetailsRequestPayload {
  id?: string;
  data: IOLTDetailsForm;
}

export interface IVoIPRequestPayload {
  voip: IVoIP | null;
}

interface IConfigRequestPayload {
  data?: ILANConfigForm | IWLANConfigForm | IVoIPRequestPayload | null;
  subscriberData?: ISubscriber | null;
}
interface ISupportedEnumPayload {
  manufacturer: string;
  harwareModel: string;
}

interface ISubscriberFormReduxState {
  subscriberFormSteps: ISubscriberFormSteps[];
  subscriberForm: ISubscriberDetailsForm;
  deviceForm: IDeviceDetailsForm;
  oltDetailsForm: IOLTDetailsForm;
  wanConfigForm: IWANConfigForm;
  lanConfigForm: ILANConfigForm | null;
  wlanConfigForm: IWLANForm | null;
  wanList: IWANConfigForm[];
  voipForm: IVoIP | null;
  voipList: IVoIP[];
  showTR069ServiceModeWarning?: boolean;
  formStepNavigation: {
    currentStepIndex: number;
    nextStepIndex: number;
  };
  formValidations: {
    listenerKey: string | number | null;
    skipAndSubmit?: boolean | null;
    isAllFormValid: boolean;
  };
  dropDownValues: {
    franchiseeList: IFranchisee[];
    subGroupList: IFiltersDropdownOptions[];
    oltList: IOLTListItems[];
    splitterList: ISplitterDropdown[];
    manufacturerList: IOptions[];
    supportedEnumValues: {
      loading: boolean;
      error: string;
      data: ISupportedEnumValues;
    };
    voipPortsSupported?: IVoIPPortsSupportedResponse | null;
  };
  submitRequestStatus?: string | null;
  disableNextButton: boolean;

  subscriberDetail: {
    loading: boolean;
    error: string | null;
    data: ISubscriber | null;
    isConfiguredSubscriber?: boolean | null;
  };
}

const initialState: ISubscriberFormReduxState = {
  subscriberFormSteps: [] as ISubscriberFormSteps[],
  subscriberForm: {} as ISubscriberDetailsForm,
  deviceForm: {} as IDeviceDetailsForm,
  oltDetailsForm: {} as IOLTDetailsForm,
  wanConfigForm: {} as IWANConfigForm,
  lanConfigForm: null,
  wlanConfigForm: null,
  wanList: [] as IWANConfigForm[],
  voipForm: null,
  voipList: [] as IVoIP[],
  showTR069ServiceModeWarning: false,
  formStepNavigation: {
    currentStepIndex: 0,
    nextStepIndex: 0,
  },
  formValidations: {
    listenerKey: null,
    isAllFormValid: false,
  },
  dropDownValues: {
    franchiseeList: [] as IFranchisee[],
    subGroupList: [] as IFiltersDropdownOptions[],
    oltList: [] as IOLTListItems[],
    splitterList: [] as ISplitterDropdown[],
    manufacturerList: [] as IOptions[],
    supportedEnumValues: {
      loading: false,
      error: '',
      data: {} as ISupportedEnumValues,
    },
    voipPortsSupported: null,
  },
  submitRequestStatus: null,
  disableNextButton: false,
  subscriberDetail: {
    loading: false,
    error: null,
    data: null,
    isConfiguredSubscriber: null,
  },
};

const fetchVoIPPortsSupported = createAsyncThunk(
  'voipPortsSupported/fetch',
  async (payload: { hardwareId: string } | void, thunkAPI) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;
    const hardwareId =
      payload?.hardwareId || subscriberFormState?.deviceForm?.hardware?.id;
    if (hardwareId) {
      const response = await HardwareRequester.get(
        formatURL(API_END_POINTS.HARDWARES.voipPortsSupported, {
          hardwareId,
        }),
        {
          params: {
            data: 'voipSupported',
          },
        },
      ).catch(({ response }) => response);
      if (response?.status === 200) {
        return response?.data;

        // // Mock Handler
        // return VOIP_PORTS_SUPPORTED_MOCK;
      } else {
        return {
          error: formatError(response) || 'messages.error.voipPortsSupported',
        };
      }
    }
  },
);

const fetchSubscriberDetail = createAsyncThunk(
  'subscriberDetail/fetch',
  async (params: { subscriberId?: string | null }, thunkAPI) => {
    if (params?.subscriberId) {
      const response: AxiosResponse = await VizService.v2.GetSubscriberById({
        subscriberId: params?.subscriberId,
      });
      if (response?.status === 200) {
        const subscriberData = response.data;

        // // Mock Handler
        // const subscriberData = window.location.pathname.includes('/pending')
        //   ? PENDING_SUBSCRIBER_BY_ID_MOCK
        //   : CONFIGURED_SUBSCRIBER_BY_ID_MOCK;

        if (subscriberData.statsSupported) {
          const statsSupported: any = {};
          subscriberData.statsSupported?.forEach((stats: string) => {
            statsSupported[stats] = true;
          });
          subscriberData.statsSupported = statsSupported;
        }
        if (subscriberData.featuresAuthorized) {
          const featuresAuthorized: any = {};
          subscriberData.featuresAuthorized?.forEach((stats: string) => {
            featuresAuthorized[stats] = true;
          });
          subscriberData.featuresAuthorized = featuresAuthorized;
        }

        if (response?.data?.featuresSupported) {
          const featuresSupported: any = {};
          subscriberData.featuresSupported?.forEach((feature: string) => {
            featuresSupported[feature] = true;
          });
          subscriberData.featuresSupported = featuresSupported;
        }

        return subscriberData;
      } else {
        return {
          error: formatError(response) || 'messages.error.subscriberDetails',
        };
      }
    }
  },
);

const fetchSupportedEnumValues = createAsyncThunk(
  'supportedEnumValues/fetch',
  async (payload: ISupportedEnumPayload, thunkAPI) => {
    const response: AxiosResponse = await VizRequester.get(
      formatURL(API_END_POINTS.VIZ.hardwareSupportedValues, {
        manufacturer: payload?.manufacturer,
        hardwareModel: payload?.harwareModel,
      }),
    ).catch(({ response }) => response);
    if (response.status === 200) {
      return response.data;
    } else {
      return {
        error: formatError(response) || 'Error in fetching Supported Values',
      };

      // //  Mock Handler
      // return SUPPORTED_ENUM_VALUES_MOCK;
    }
  },
);

const updateOLTDetails = createAsyncThunk(
  'oltDetails/update',
  async (payload: IOLTDetailsRequestPayload, thunkAPI) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;

    const response: AxiosResponse =
      await OLTOnboardingService.v1.UpdateSubscriberOLTDetails(
        {
          subscriberId:
            payload?.id ||
            subscriberFormState?.subscriberDetail?.data?.subscriberId ||
            '',
        },
        payload?.data,
      );
    if (response?.status === 200) {
      return response.data;
    } else {
      return {
        error: formatError(response) || 'messages.error.somethingWentWrong',
      };
    }
  },
);

const fetchLANConfig = createAsyncThunk(
  'lanConfig/fetch',
  async (
    payload: { subscriberData?: ISubscriber | null } | null | undefined,
    thunkAPI,
  ) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;

    const subscriberData: ISubscriber | null =
      payload?.subscriberData || subscriberFormState?.subscriberDetail?.data;

    const response: AxiosResponse = await Subscriber2Requester.get(
      formatURL(API_END_POINTS.SUBSCRIBER.lan, {
        subscriberId: subscriberData?.subscriberId,
        mac: subscriberData?.device?.mac,
      }),
      { baseURL: Subscriber2Requester?.getUri()?.replace('v1', 'v2') },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;

      // // Mock Handler
      // return LAN_CONFIG_MOCK;
    } else {
      return {
        error: formatError(response) || 'messages.error.somethingWentWrong',
      };

      // // Mock Handler
      // return LAN_CONFIG_MOCK;
    }
  },
);

const updateLANConfig = createAsyncThunk(
  'lanConfig/update',
  async (payload: IConfigRequestPayload, thunkAPI) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;
    const subscriberData: ISubscriber | null =
      payload?.subscriberData || subscriberFormState?.subscriberDetail?.data;

    const response: AxiosResponse = await Subscriber2Requester.put(
      formatURL(API_END_POINTS.SUBSCRIBER.lan, {
        subscriberId: subscriberData?.subscriberId,
        mac: subscriberData?.device?.mac,
      }),
      payload?.data,
      { baseURL: Subscriber2Requester?.getUri()?.replace('v1', 'v2') },
    ).catch(({ response }) => response);
    if (response?.status === 202) {
      return response.data;
    } else {
      return {
        error: formatError(response) || 'messages.error.somethingWentWrong',
      };
    }
  },
);

const fetchWLANConfig = createAsyncThunk(
  'wlanConfig/fetch',
  async (
    payload: { subscriberData?: ISubscriber | null } | null | undefined,
    thunkAPI,
  ) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;

    const subscriberData: ISubscriber | null =
      payload?.subscriberData || subscriberFormState?.subscriberDetail?.data;

    const response: AxiosResponse = await Subscriber2Requester.get(
      formatURL(API_END_POINTS.SUBSCRIBER.wlan, {
        subscriberId: subscriberData?.subscriberId,
        mac: subscriberData?.device?.mac,
      }),
      { baseURL: Subscriber2Requester?.getUri()?.replace('v1', 'v2') },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;
      // // Mock Handler
      // return WLAN_CONFIG_LIST_MOCK;
    } else {
      return {
        error: formatError(response) || 'messages.error.somethingWentWrong',
      };

      // // Mock Handler
      // return WLAN_CONFIG_LIST_MOCK;
    }
  },
);

const updateWLANConfig = createAsyncThunk(
  'wlanConfig/update',
  async (payload: IConfigRequestPayload, thunkAPI) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;
    const subscriberData: ISubscriber | null =
      payload?.subscriberData || subscriberFormState?.subscriberDetail?.data;
    const response: AxiosResponse = await Subscriber2Requester.put(
      formatURL(API_END_POINTS.SUBSCRIBER.wlan, {
        subscriberId: subscriberData?.subscriberId,
        mac: subscriberData?.device?.mac,
      }),
      payload?.data,
      { baseURL: Subscriber2Requester?.getUri()?.replace('v1', 'v2') },
    ).catch(({ response }) => response);
    if (response?.status === 202) {
      return response.data;
    } else {
      return {
        error: formatError(response) || 'messages.error.somethingWentWrong',
      };
    }
  },
);

const fetchVoIPConfig = createAsyncThunk(
  'voipConfig/fetch',
  async (payload: IConfigRequestPayload | void, thunkAPI) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;

    const subscriberData: ISubscriber | null =
      payload?.subscriberData || subscriberFormState?.subscriberDetail?.data;

    const response: AxiosResponse = await Subscriber2Requester.get(
      formatURL(API_END_POINTS.SUBSCRIBER.voip, {
        subscriberId: subscriberData?.subscriberId,
        mac: subscriberData?.device?.mac,
      }),
      { baseURL: Subscriber2Requester?.getUri()?.replace('v1', 'v2') },
    ).catch(({ response }) => response);
    if (response?.status === 200) {
      return response.data;

      // // Mock Handler
      // return VOIP_CONFIG_MOCK;
    } else {
      return {
        error: formatError(response) || 'messages.error.voipConfig',
      };

      // // Mock Handler
      // return VOIP_CONFIG_MOCK;
    }
  },
);

const updateVoIPConfig = createAsyncThunk(
  'voipConfig/update',
  async (payload: IConfigRequestPayload, thunkAPI) => {
    const {
      subscriberFormState,
    }: { subscriberFormState: ISubscriberFormReduxState } =
      thunkAPI.getState() as any;
    const subscriberData: ISubscriber | null =
      payload?.subscriberData || subscriberFormState?.subscriberDetail?.data;
    const response: AxiosResponse = await Subscriber2Requester.put(
      formatURL(API_END_POINTS.SUBSCRIBER.voip, {
        subscriberId: subscriberData?.subscriberId,
        mac: subscriberData?.device?.mac,
      }),
      payload?.data,
      { baseURL: Subscriber2Requester?.getUri()?.replace('v1', 'v2') },
    ).catch(({ response }) => response);
    if (response?.status === 202) {
      return response.data;
    } else {
      return {
        error: formatError(response) || 'messages.error.updateVoipConfig',
      };
    }
  },
);

export const SubscriberFormSlice = createSlice({
  name: 'SubscriberForm',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    setFormStepper: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: ISubscriberFormSteps[] },
    ) => {
      state.subscriberFormSteps = payload;
    },

    setFranchiseeList: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: IFranchisee[] },
    ) => {
      state.dropDownValues.franchiseeList = payload;
    },

    setSubscriberGroupList: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: IFiltersDropdownOptions[] },
    ) => {
      state.dropDownValues.subGroupList = payload;
    },

    setOLTList: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: IOLTListItems[] },
    ) => {
      state.dropDownValues.oltList = payload;
    },

    setSplitterList: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: ISplitterDropdown[] },
    ) => {
      state.dropDownValues.splitterList = payload;
    },

    setManufacturerList: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: IOptions[] },
    ) => {
      state.dropDownValues.manufacturerList = payload;
    },

    setVoIPList: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: IVoIP[] },
    ) => {
      state.voipList = payload || [];
    },

    setShowTR069ServiceModeWarning: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.showTR069ServiceModeWarning = payload;
    },

    setFormValues: (
      state: ISubscriberFormReduxState,
      {
        payload,
      }: {
        payload: { formName: SUBSCRIBER_FORM_NAMES; values: TFormValues };
      },
    ) => {
      let stepKey = SUBSCRIBER_FORM_STEPS.SUBSCRIBER_DETAILS;
      switch (payload?.formName) {
        case SUBSCRIBER_FORM_NAMES.SUBSCRIBER_DETAILS:
          state.subscriberForm = payload?.values as ISubscriberDetailsForm;
          stepKey = SUBSCRIBER_FORM_STEPS.SUBSCRIBER_DETAILS;
          break;
        case SUBSCRIBER_FORM_NAMES.DEVICE_DETAILS:
          const value = payload?.values as IDeviceDetailsForm;
          if (state?.deviceForm?.hardware?.band !== value?.hardware?.band) {
            state.wlanConfigForm = null;
          }
          if (state?.deviceForm?.hardware?.id !== value?.hardware?.id) {
            state.wanList = [];
            state.voipList = [];
          }
          state.deviceForm = payload?.values as IDeviceDetailsForm;
          stepKey = SUBSCRIBER_FORM_STEPS.DEVICE_DETAILS;
          break;
        case SUBSCRIBER_FORM_NAMES.WAN_CONFIG:
          const values = payload?.values as IWANConfigForm;
          if (values?.editWANIndex || values?.editWANIndex === 0) {
            state.wanList[values.editWANIndex] = values;
          } else {
            state.wanList = !isEmpty(state.wanList)
              ? [...state.wanList, { ...values }]
              : [values];
          }
          stepKey = SUBSCRIBER_FORM_STEPS.WAN;
          break;
        case SUBSCRIBER_FORM_NAMES.LAN_CONFIG:
          state.lanConfigForm = payload?.values as ILANConfigForm;
          break;
        case SUBSCRIBER_FORM_NAMES.WLAN_CONFIG_2_4:
        case SUBSCRIBER_FORM_NAMES.WLAN_CONFIG_5_0:
          state.wlanConfigForm = {
            ...(state.wlanConfigForm as IWLANForm),
            ...(payload.values as IWLANForm),
          };
          stepKey = SUBSCRIBER_FORM_STEPS.LAN;
          break;
        case SUBSCRIBER_FORM_NAMES.VOIP_CONFIG:
          const voipValues = payload?.values as IVoIP;
          const voipIndex: number = state.voipList?.findIndex(
            (val) => val?.voipPortId === voipValues?.voipPortId,
          );
          if (voipIndex >= 0) {
            state.voipList[voipIndex] = voipValues;
          } else {
            state.voipList = !isEmpty(state?.voipList)
              ? [...state?.voipList, { ...voipValues }]
              : [voipValues];
          }
          stepKey = SUBSCRIBER_FORM_STEPS.VOIP;
          break;
      }
      SubscriberFormSlice.caseReducers.setValidationStatus(state, {
        payload: {
          stepKey: stepKey,
          status: 'finish',
          formName: payload.formName,
        },
      });
      // state.formValidations.forms[payload.formName].status = 'finish';
    },

    removeWANByIndex: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: number },
    ) => {
      state.wanList.splice(payload, 1);
    },

    setValidationStatus: (
      state: ISubscriberFormReduxState,
      {
        payload,
      }: {
        payload: {
          stepKey: SUBSCRIBER_FORM_STEPS;
          status: StepProps['status'];
          formName?: SUBSCRIBER_FORM_NAMES;
        };
      },
    ) => {
      const stepIndex = state.subscriberFormSteps?.findIndex(
        (val) => val?.key === payload?.stepKey,
      );
      if (stepIndex !== -1) {
        state.subscriberFormSteps[stepIndex].status = payload.status;
      }
      // if (payload?.formName) {
      //   state.formValidations.forms[payload.formName].status = payload.status;
      // }
      if (!state.formValidations.skipAndSubmit) {
        const totalSteps = state?.subscriberFormSteps?.filter((v) => !v.hidden);
        const validSteps = totalSteps?.filter((v) => v?.status === 'finish');
        state.formValidations.isAllFormValid =
          totalSteps?.length === validSteps?.length;
      } else {
        state.formValidations.isAllFormValid = payload?.status === 'finish';
      }
    },

    setIsAllFormValid: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.formValidations.isAllFormValid = payload;
      state.formValidations.skipAndSubmit = null;
    },

    setCurrentStepIndex: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: number },
    ) => {
      state.formStepNavigation.currentStepIndex = payload;
      state.formStepNavigation.nextStepIndex = payload; // To reset next step index value
      state.formValidations.listenerKey = null;
      state.formValidations.skipAndSubmit = null;
    },

    setNextStepIndex: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: number },
    ) => {
      state.formStepNavigation.nextStepIndex = payload;
      state.formValidations.listenerKey = Math.random();
      state.formValidations.skipAndSubmit = null;
    },

    handleSubmit: (
      state: ISubscriberFormReduxState,
      {
        payload,
      }: { payload: { skipAndSubmit?: boolean | null } | undefined | null },
    ) => {
      state.formValidations.listenerKey = Math.random();
      state.formValidations.isAllFormValid = false;
      state.formValidations.skipAndSubmit = payload?.skipAndSubmit;
    },

    clearListenerKey: (state: ISubscriberFormReduxState) => {
      state.formValidations.listenerKey = null;
    },

    setSubmitRequestStatus: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: string | null },
    ) => {
      state.submitRequestStatus = payload;
    },

    setDisableNextButton: (
      state: ISubscriberFormReduxState,
      { payload }: { payload: boolean },
    ) => {
      state.disableNextButton = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      fetchSupportedEnumValues.pending,
      (state: ISubscriberFormReduxState) => {
        state.dropDownValues.supportedEnumValues.loading = true;
        state.dropDownValues.supportedEnumValues.error = '';
      },
    );
    builder.addCase(
      fetchSupportedEnumValues.fulfilled,
      (state, { payload }: { payload: ISupportedEnumValues | any }) => {
        state.dropDownValues.supportedEnumValues.loading = false;
        if (isEmpty(payload?.error)) {
          state.dropDownValues.supportedEnumValues.data = payload;
        } else {
          state.dropDownValues.supportedEnumValues.error = payload?.error;
        }
      },
    );

    builder.addCase(
      fetchVoIPPortsSupported.fulfilled,
      (state, { payload }: { payload: ISupportedEnumValues | any }) => {
        if (isEmpty(payload?.error)) {
          state.dropDownValues.voipPortsSupported = payload;
        }
      },
    );

    builder.addCase(
      fetchSubscriberDetail.pending,
      (state: ISubscriberFormReduxState) => {
        state.subscriberDetail.loading = true;
        state.subscriberDetail.isConfiguredSubscriber = false;
      },
    );
    builder.addCase(
      fetchSubscriberDetail.fulfilled,
      (state, { payload }: { payload: ISubscriber | any }) => {
        state.subscriberDetail.loading = false;
        if (isEmpty(payload?.error)) {
          state.subscriberDetail.data = payload;
          const subscriberData = { ...payload };
          state.subscriberDetail.isConfiguredSubscriber =
            subscriberData.onboardingState === ONBOARDING_STATE.PROVISIONED;

          //  Update form data in redux state
          const subscriberPhoneNumber = splitCountryCodeAndPhoneNumber(
            subscriberData?.phoneNumber,
          );
          subscriberData.phoneNumber = subscriberPhoneNumber?.phoneNumber;
          subscriberData.countryCode = subscriberPhoneNumber?.countryCode;
          const splitterData = subscriberData?.ponMap?.splitter?.find(
            (val: ISplitter) => val?.isParent,
          );
          state.subscriberForm = (({ hardware, wan, lan, wlan, ...rest }) =>
            rest)(subscriberData);
          state.deviceForm = { hardware: subscriberData?.hardware };
          state.oltDetailsForm = {
            device: { oltDetails: subscriberData?.device?.oltDetails },
            splitter: splitterData || null,
          };
          state.wanList = subscriberData?.wan;
          state.lanConfigForm = subscriberData?.lan;
          state.wlanConfigForm = {
            [FrequencyBand._2_4GHZ]: subscriberData?.wlan?.find(
              (v: IWLANConfigForm) => v?.band === FrequencyBand._2_4GHZ,
            ),
            [FrequencyBand._5GHZ]: subscriberData?.wlan?.find(
              (v: IWLANConfigForm) => v?.band === FrequencyBand._5GHZ,
            ),
          };
          state.voipList = subscriberData?.voip;
        } else {
          state.subscriberDetail.error = payload?.error;
        }
      },
    );

    builder.addCase(
      updateOLTDetails.pending,
      (state: ISubscriberFormReduxState) => {
        state.submitRequestStatus = LOADING;
      },
    );
    builder.addCase(
      updateOLTDetails.fulfilled,
      (
        state: ISubscriberFormReduxState,
        { payload }: { payload: IPayloadError },
      ) => {
        state.submitRequestStatus = payload.error || null;
      },
    );

    builder.addCase(
      updateLANConfig.pending,
      (state: ISubscriberFormReduxState) => {
        state.submitRequestStatus = LOADING;
      },
    );
    builder.addCase(
      updateLANConfig.fulfilled,
      (
        state: ISubscriberFormReduxState,
        { payload }: { payload: IPayloadError },
      ) => {
        state.submitRequestStatus = payload.error || null;
      },
    );

    builder.addCase(
      updateWLANConfig.pending,
      (state: ISubscriberFormReduxState) => {
        state.submitRequestStatus = LOADING;
      },
    );
    builder.addCase(
      updateWLANConfig.fulfilled,
      (
        state: ISubscriberFormReduxState,
        { payload }: { payload: IPayloadError },
      ) => {
        state.submitRequestStatus = payload.error || null;
      },
    );

    builder.addCase(
      updateVoIPConfig.pending,
      (state: ISubscriberFormReduxState) => {
        state.submitRequestStatus = LOADING;
      },
    );
    builder.addCase(
      updateVoIPConfig.fulfilled,
      (
        state: ISubscriberFormReduxState,
        { payload }: { payload: IPayloadError },
      ) => {
        state.submitRequestStatus = payload.error || null;
      },
    );
  },
});

export const SubscriberFormActions = {
  ...SubscriberFormSlice.actions,
  fetchSupportedEnumValues,
  fetchSubscriberDetail,
  updateOLTDetails,
  fetchVoIPPortsSupported,
  fetchLANConfig,
  fetchWLANConfig,
  fetchVoIPConfig,
  updateLANConfig,
  updateWLANConfig,
  updateVoIPConfig,
};

export const SubscriberFormReducers = SubscriberFormSlice.reducer;
