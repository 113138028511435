import {
  EntityType,
  FrequencyBand,
  IOptions,
  MANAGEMENT_PROTOCOL,
  OnboardingState,
  OnlineStatus,
} from 'store/types';
import {
  FIRMWARE_LAUNCHER_MODE,
  FREQUENCY_BAND,
  IOLTDetails,
  ISubscriberResponse,
} from 'store/types/subscriber.types';
import { LAN_CONFIG_MOCK, WLAN_CONFIG_LIST_MOCK } from './wlanConfig.mock';
import {
  generateRandomInteger,
  generateRandomIp,
  generateRandomMAC,
  generateRandomStringId,
} from 'mocks/mock.utils';

import { ACCESS_TYPE_DISPLAY_NAME } from 'store/types/subscriberDashboard.types';
import { VOIP_CONFIG_MOCK } from './voipConfig.mock';
import { WAN_CONFIG_LIST_MOCK } from './wanConfig.mock';
import moment from 'moment';

const SubscriberListTotalCount = 50;

const DefaultSubscriberMac = generateRandomMAC();
export const DEFAULT_SUBSCRIBER_MOCK: ISubscriberResponse = {
  subscriberId: `SUB-${generateRandomStringId()}`,
  name: 'Sub 1',
  emailId: undefined,
  phoneNumber: `+91-${generateRandomInteger(10)}`,
  secondaryPhoneNumber: undefined,
  address: {
    addressLine1: 'Sony service centre City electronics,Dindigul.',
    areaCode: '624001',
  },
  onboardingState: OnboardingState.PROVISIONED,
  subscriberGroupId: 'd484913ebcda46ddbaa430582aab8906',
  device: {
    mac: DefaultSubscriberMac,
    serialNumber: generateRandomStringId(18, true),
    deviceStatus: 'ACTIVE',
    manufacturer: 'syrotech',
    managementProtocol: MANAGEMENT_PROTOCOL.AGENT,
    onlineStatus: OnlineStatus.ONLINE,
    upTime: 32911,
    lastSeenTime: 'NA',
    publicIp: generateRandomIp(),
    wanIp: generateRandomIp(),
    radios: [
      {
        frequency: '2.4 GHz',
        band: '2.4 GHz',
        channel: 5,
        clientCount: 3,
        channelWidth: '40',
      },
    ],
    numberOfRadios: 1,
    hardwareId: '4d9ec491579e4c4c89004953c5e0588a',
    hardwareModel: 'SY-GPON-1110-WDONT',
    firmwareVersion: '2.0.10-210616',
    agentVersion: 'singleBand_v30.0.0-p1',
    isBaseFirmwareUpgradeRequired: null,
    baseFirmwareLink: null,
    isFirmwareUpgradeRequired: null,
    firmwareLink: null,
    cloudEnabled: null,
    ports: {
      ethernet: [
        {
          port: 1,
          type: 'lan',
          active: false,
          disabled: false,
        },
        {
          port: 2,
          type: 'lan',
          active: true,
          disabled: false,
        },
      ],
      fxs: [],
      gpon: {
        active: true,
        disabled: false,
      },
    },
    accessType: ACCESS_TYPE_DISPLAY_NAME.GPON,
    hardwareVersion: 'V3.7',
    wlans: [
      {
        band: '2.4 GHz',
        bssid: '7c:a9:6b:38:f7:0d',
        channel: 5,
        clientCount: 3,
        ssid: generateRandomStringId(8),
        id: 'wlan0',
        enabled: true,
      },
    ],
    band: ['2.4 GHz'],
    wifiEnabled: true,
    agentDetails: {
      mode: FIRMWARE_LAUNCHER_MODE.LAUNCHER,
      launcherVersion: 'fii_syrotech_G1110WDONT_3.6AND3.7_v27.0.0-1',
      fiiCoreVersion: 'singleBand_v30.0.0-p1',
      agentVersion: null,
    },
    oltDetails: {
      portNumber: 5,
      ponPortCount: 8,
      label: 'OLT_GPON',
      mac: generateRandomMAC(),
      ipAddress: generateRandomIp(),
      ontNumber: '35',
      distance: generateRandomInteger(4)?.toString(),
      portName: 'PON5',
      portIndex: '5',
      updatedTime: '2024-04-17T10:08:38.866Z',
    },
    wanSettings: {
      pppoe: {
        username: `id${generateRandomInteger(10)}_sid@ftth.bsnl.in`,
      },
    },
    optical: {
      rxPower: null,
      txPower: null,
      strength: null,
    },
    rtt: '2285',
    vlanId: 50,
    offlineReasonCode: 'UNDEFINED',
    ponSerialNumber: 'GPON0038F70D',
  },
  invitationId: null,
  managementEntities: [
    {
      id: 'PLATFORM',
      name: 'PLATFORM',
      type: EntityType.PLATFORM,
      isParent: false,
    },
    {
      id: '99807ae77fb84e1f8399311ce0971f47',
      name: 'Default CP',
      type: EntityType.CHANNEL_PARTNER,
      isParent: false,
    },
    {
      id: '2b7507321b974177960738b9b92b01a6',
      name: 'ISP 1',
      type: EntityType.ISP,
      isParent: false,
    },
    {
      id: '614ead8510ea4c9897a36928ceb8472c',
      name: 'Franchisee 1',
      type: EntityType.FRANCHISEE,
      isParent: true,
    },
  ],
  statsSupported: [
    'ConnectedClients',
    'CpuUsage',
    'MemoryUsage',
    'OpticalLevel',
    'Channel',
    'ChannelWidth',
    'Uptime',
    'ConnectedPorts',
    'WanThroughput',
    'WiFiThroughput',
    'ClientDeviceRSSI',
    'ClientDeviceThroughput',
    'ProfileUsage',
    'DeviceLogs',
    'WanType',
    'WanPublicIP',
    'WanIP',
    'OnlineOfflineStatus',
    'SystemEventAndLogs',
    'EthernetPortStatus',
    'NetworkInformation',
    'RadioInformation',
    'OpticalStrength',
    'PONAccessType',
    'ChannelSwitch',
  ],
  featuresSupported: [
    'SiteSurvey',
    'PausePlay',
    'LanConfig',
    'AppBlock',
    'Stats',
    'Reboot',
    'SSID',
    'WifiOptimize',
    'Profile',
    'AdBlock',
    'Speedtest',
    'FirmwareUpdate',
    'ScanAndOptimizeWifi',
    'IncidentChat',
    'FirmwareManagement',
    'Traceroute',
    'SSIDPassword',
    'Ping',
    'InternetSecurity',
    'ParentalControl',
    'DeviceManagement',
    'Safesearch',
  ],
  featuresAuthorized: [
    'SiteSurvey',
    'FirmwareManagement',
    'PausePlay',
    'LanConfig',
    'Traceroute',
    'Speedtest',
    'Stats',
    'Reboot',
    'SSIDPassword',
    'DeviceManagement',
    'Ping',
    'Profile',
    'ScanAndOptimizeWifi',
  ],
  subscriberSince: '2023-02-23 10:18:39.764349',
  logRetentionDays: 7,
  trialEnabled: false,
  friendly: false,
  cpeId: DefaultSubscriberMac,
  powerRating: {
    temperature: 40.04297,
    voltage: 3.394,
    biasCurrent: 15.9,
  },
  olt: null,
  serviceProfile: null,
  lineProfile: null,
  manufacturer: null,
  PONSerialNumber: null,
  PPPOEServiceName: null,
  PPPOEUserName: null,
  PPPOEPassword: null,
  vlanId: null,
  isWanProvisioningEnabled: true,
  wan: null,
  lan: null,
  wlan: null,
  pendingConfigurationCount: 0,
  voip: null,
  createdTransactionsCount: 0,
  hardware: {
    band: FREQUENCY_BAND.SINGLE,
    manufacturer: '',
    model: '',
  },
};

export const SUBSCRIBERS_LIST_MOCK: {
  items: ISubscriberResponse[];
  pageSize: number;
  pageToken: string;
  total: number;
  pageNumber: number;
} = {
  items: Array.from({ length: SubscriberListTotalCount }, (_, idx) => {
    const onlineStatus =
      idx % 2 === 0 ? OnlineStatus.ONLINE : OnlineStatus.OFFLINE;
    const mgmtProtocolList = Object.values(MANAGEMENT_PROTOCOL)?.filter(
      (val) => val === MANAGEMENT_PROTOCOL.MULTIMODE,
    );

    const managementProtocol =
      idx < mgmtProtocolList?.length
        ? mgmtProtocolList[idx]
        : mgmtProtocolList[idx % mgmtProtocolList?.length];
    const mac = generateRandomMAC();
    const band =
      idx % 2 !== 0 && idx < SubscriberListTotalCount / 2
        ? ['2.4 GHz']
        : ['2.4 GHz', '5.0 GHz'];

    let customValues: ISubscriberResponse = {
      subscriberId: `SUB-${moment()?.day()}-${idx}`,
      name: `Sub-Test-${idx + 1}`,
      phoneNumber: Math.random()?.toString()?.substring(2, 12),
      // @ts-ignore
      connectionStatus: onlineStatus,
      // @ts-ignore
      onboardingStatus: OnboardingState.CONFIGURED,
      band: band?.length ? (band?.length === 2 ? 'dual' : 'single') : undefined,

      cpeId: mac,
      device: {
        ...DEFAULT_SUBSCRIBER_MOCK.device,
        mac,
        serialNumber: generateRandomStringId(),
        managementProtocol,
        band: idx % 2 ? ['2.4 GHz', '5.0 GHz'] : ['2.4 GHz'],
        onlineStatus: onlineStatus,
        numberOfRadios: band?.length as 1 | 2,
      },
      subscriberSince: moment()?.subtract(idx, 'day')?.toISOString(),
      logRetentionDays: 0,
      olt: DEFAULT_SUBSCRIBER_MOCK.olt as IOLTDetails,
      alreadyUnassigned: false,
      address: DEFAULT_SUBSCRIBER_MOCK.address,
      hardware: {
        band: FREQUENCY_BAND.SINGLE,
        manufacturer: '',
        model: '',
        version: undefined,
        id: undefined,
      },
    };
    return { ...DEFAULT_SUBSCRIBER_MOCK, ...customValues };
  }),
  pageSize: 10,
  pageToken:
    'eyJvcmlnaW5hbFBhZ2VUb2tlbiI6ICIxNzIxMDQwMjM5Nzg1IiwgImxhc3RFdmFsdWF0ZWRQYWdlTnVtYmVyIjogMSwgImVuZFRpbWUiOiBudWxsfQ==',
  total: SubscriberListTotalCount,
  pageNumber: 1,
};

export const SUBSCRIBER_BULK_UPLOAD_GET_MOCK = {
  id: '9160bbea37184b58975ef22ba70d70fa',
  status: 'COMPLETED',
  managementEntityName: 'PLATFORM',
  managementEntityId: 'PLATFORM',
  totalCount: 9,
  success: 0,
  failed: 9,
  requestFile:
    'https://fii-dev-dataingest.s3.ap-south-1.amazonaws.com/subscribers/PLATFORM/9160bbea37184b58975ef22ba70d70fa/request_9160bbea37184b58975ef22ba70d70fa.xlsx',
  responseFile:
    'https://fii-dev-dataingest.s3.ap-south-1.amazonaws.com/subscribers/PLATFORM/9160bbea37184b58975ef22ba70d70fa/response_9160bbea37184b58975ef22ba70d70fa.xlsx',
  subscribers: [
    {
      id: null,
      name: 'SUGAVANAM 2c',
      phoneNumber: 9994389644,
      email: null,
      address: {
        addressLine1: '2nd cross,manakadu',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 200,
      status: 'CREATED',
      message: null,
    },
    {
      id: null,
      name: 'SHANKAR kk',
      phoneNumber: 9790188582,
      email: null,
      address: {
        addressLine1: 'kannankurchi main road',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 200,
      status: 'SMS_SKIPPED',
      message: null,
    },
    {
      id: null,
      name: 'akbar ws',
      phoneNumber: 9361249110,
      email: null,
      address: {
        addressLine1: 'west pilliyar koil street',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
    {
      id: null,
      name: 'karuppiya an',
      phoneNumber: 9965216215,
      email: null,
      address: {
        addressLine1: 'anbu nagar',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
    {
      id: null,
      name: 'velayatha rk',
      phoneNumber: 9500985689,
      email: null,
      address: {
        addressLine1: 'ramakrishna road',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
    {
      id: null,
      name: 'pugazhanthi rr',
      phoneNumber: 9965558810,
      email: null,
      address: {
        addressLine1: 'rajaramnagar',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
    {
      id: null,
      name: 'ba vaithilingam ',
      phoneNumber: 9443324571,
      email: null,
      address: {
        addressLine1: 'balaji avanue',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
    {
      id: null,
      name: 'ad charles',
      phoneNumber: 9443487926,
      email: null,
      address: {
        addressLine1: 'adakal nagar',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
    {
      id: null,
      name: 'ad farnado',
      phoneNumber: 9788232221,
      email: null,
      address: {
        addressLine1: 'adakal nagar',
        areaCode: '636007',
      },
      deviceMac: '14:A7:2B:3F:AD:2D',
      statusCode: 400,
      status: 'CREATION_FAILED',
      message: 'Phone number already registered',
    },
  ],
};

export const SUBSCRIBER_DASHBOARD_OMCI_MOCK = {
  subscriberId: 'bc692b319e254af7971dd162089b583f',
  name: 'A0:C6:C1:11:22:34',
  emailId: null,
  phoneNumber: '+910000000007',
  secondaryPhoneNumber: null,
  address: {
    addressLine1: 'Karapakkam  ',
    areaCode: '600118',
  },
  onboardingState: 'PROVISIONED',
  subscription: {
    packageId: '0a32839bd2ff47a68481c263c8126f2b',
    packageName: 'ESSENTIAL',
    displayName: 'Essential',
    validity: '1 month',
    state: 'ACTIVE',
    startTime: '2023-06-27 08:36:34.525084',
    endTime: '2023-06-27 08:36:34.525090',
  },
  subscriberGroupId: '8cff7f4c8eac4095a7da9fa10b140e84',
  device: {
    mac: 'A0:C6:C1:11:22:34',
    serialNumber: null,
    deviceStatus: 'ACTIVE',
    manufacturer: null,
    managementProtocol: 'OMCI',
    onlineStatus: 'OFFLINE',
    upTime: null,
    lastSeenTime: null,
    publicIp: null,
    wanIp: null,
    radios: [],
    numberOfRadios: 0,
    hardwareId: 'UHW-31f52451',
    hardwareModel: 'E106',
    firmwareVersion: null,
    agentVersion: null,
    isBaseFirmwareUpgradeRequired: null,
    baseFirmwareLink: null,
    isFirmwareUpgradeRequired: null,
    firmwareLink: null,
    cloudEnabled: null,
    ports: {
      ethernet: [],
      fxs: [],
      gpon: {
        active: false,
        disabled: false,
      },
    },
    accessType: null,
    hardwareVersion: 'V3.2',
    wlans: [],
    band: null,
    wifiEnabled: null,
    agentDetails: null,
    ontNumber: null,
    oltDetails: {
      portNumber: 1,
      ponPortCount: 4,
      label: 'NAGARAJ-CHAKRA',
      mac: '14:A7:2B:BC:23:2D',
      ipAddress: '10.10.14.91',
    },
  },
  invitationId: null,
  managementEntities: [
    {
      id: 'PLATFORM',
      name: 'PLATFORM',
      type: 'PLATFORM',
      isParent: false,
    },
    {
      id: '57deb6f29d5441c18e3d6824566da818',
      name: 'CP1',
      type: 'CHANNEL_PARTNER',
      isParent: false,
    },
    {
      id: '0a6afc9d9dbb449bb01294641a26478f',
      name: 'ISP1',
      type: 'ISP',
      isParent: true,
    },
  ],
  statsSupported: null,
  featuresSupported: [],
  featuresAuthorized: [],
  subscriberSince: 'None',
  logRetentionDays: 7,
  trialEnabled: false,
  friendly: false,
  tags: {
    TRIAL: 'false',
  },
  cpeId: 'A0:C6:C1:11:22:34',
};

export const CONFIGURED_SUBSCRIBER_DASHBOARD_MOCK: ISubscriberResponse = {
  subscriberId: 'b85453d2bdc340cfb7f2b178a500bf95',
  name: 'Nirmala Devi',
  emailId: 'dipakkr0221@gmail.com ',
  phoneNumber: '+918084090516',
  secondaryPhoneNumber: '+912356253655',
  address: {
    addressLine1: 'om nagar ward no8 Araria Araria',
    areaCode: '854311',
  },
  onboardingState: OnboardingState.PROVISIONED,
  subscriberGroupId: 'f7f939e28b534415a2266d316ac17f78',
  device: {
    mac: '14:A7:2B:CE:84:1E',
    serialNumber: '123454CAE1C77A065',
    deviceStatus: 'ACTIVE',
    manufacturer: 'DBC',
    managementProtocol: MANAGEMENT_PROTOCOL.AGENT,
    onlineStatus: OnlineStatus.ONLINE,
    upTime: 7691,
    lastSeenTime: 'NA',
    publicIp: '103.184.11.68',
    wanIp: '194.167.1.4',
    radios: [
      {
        frequency: FrequencyBand._5GHZ,
        band: FrequencyBand._5GHZ,
        channel: 149,
        clientCount: 1,
        channelWidth: '80',
      },
      {
        frequency: FrequencyBand._2_4GHZ,
        band: FrequencyBand._2_4GHZ,
        channel: 1,
        clientCount: 3,
        channelWidth: '20',
      },
    ],
    numberOfRadios: 2,
    hardwareId: 'dbc262c5573eb904d319c5b5b1acf179ecb',
    hardwareModel: 'DBC_HG323DAC',
    firmwareVersion: 'V2.1.08-231113',
    agentVersion: 'dualBand_v26.0.0-1',
    isBaseFirmwareUpgradeRequired: null,
    baseFirmwareLink: null,
    isFirmwareUpgradeRequired: null,
    firmwareLink: null,
    cloudEnabled: null,
    ports: {
      ethernet: [
        {
          port: 1,
          type: 'lan',
          active: false,
          disabled: false,
        },
        {
          port: 2,
          type: 'lan',
          active: false,
          disabled: false,
        },
      ],
      fxs: [],
      gpon: {
        active: true,
        disabled: false,
      },
    },
    accessType: ACCESS_TYPE_DISPLAY_NAME.EPON,
    hardwareVersion: 'V4.1',
    wlans: [
      {
        band: '5.0 GHz',
        bssid: '4c:ae:1c:77:a0:66',
        channel: 149,
        clientCount: 1,
        ssid: 'NS BROADBAND-5G',
        id: 'wlan0',
        enabled: true,
      },
      {
        band: '2.4 GHz',
        bssid: '4c:ae:1c:77:a0:6a',
        channel: 1,
        clientCount: 3,
        ssid: 'NS BROADBAND',
        id: 'wlan1',
        enabled: true,
      },
    ],
    band: ['2.4 GHz', '5.0 GHz'],
    wifiEnabled: true,
    agentDetails: {
      mode: FIRMWARE_LAUNCHER_MODE.LAUNCHER,
      launcherVersion: 'fii_dbc_hg323dac_4.1AND4.2_v26.0.0-1',
      fiiCoreVersion: 'dualBand_v26.0.0-1',
      agentVersion: null,
    },
    oltDetails: {
      portNumber: 3,
      ponPortCount: 8,
      label: 'AR1-OLT',
      mac: '80:14:A8:97:40:8F',
      ipAddress: '192.168.66.21',
      ontNumber: '11',
      distance: '1004',
      portName: 'EPON0/3',
      portIndex: '3',
    },
    wanSettings: {
      pppoe: {
        username: 'ardipak16',
      },
    },
    optical: {
      rxPower: null,
      txPower: null,
      strength: null,
    },
    rtt: '1004',
    vlanId: 567,
    offlineReasonCode: 'UNKNOWN',
  },
  invitationId: null,
  managementEntities: [
    {
      id: '52736a2efc7c47fca39429a2eb367b14',
      name: 'NSB NETWORKS PVT LTD',
      type: EntityType.ISP,
      isParent: true,
    },
    {
      id: '99807ae77fb84e1f8399311ce0971f47',
      name: 'Vadivel',
      type: EntityType.CHANNEL_PARTNER,
      isParent: false,
    },
    {
      id: 'PLATFORM',
      name: 'PLATFORM',
      type: EntityType.PLATFORM,
      isParent: false,
    },
  ],
  // @ts-ignore
  statsSupported: [
    'ConnectedClients',
    'CpuUsage',
    'MemoryUsage',
    'OpticalLevel',
    'Channel',
    'ChannelWidth',
    'Uptime',
    'ConnectedPorts',
    'WanThroughput',
    'WiFiThroughput',
    'ClientDeviceRSSI',
    'ClientDeviceThroughput',
    'ProfileUsage',
    'DeviceLogs',
    'WanType',
    'WanPublicIP',
    'WanIP',
    'OnlineOfflineStatus',
    'SystemEventAndLogs',
    'EthernetPortStatus',
    'NetworkInformation',
    'RadioInformation',
    'OpticalStrength',
    'PONAccessType',
  ],
  // @ts-ignore
  featuresSupported: [
    'FirmwareManagement',
    'Reboot',
    'DeviceManagement',
    'IncidentChat',
    'Profile',
    'InternetSecurity',
    'Ping',
    'ParentalControl',
    'Traceroute',
    'AdBlock',
    'AppBlock',
    'Speedtest',
    'PausePlay',
    'Safesearch',
    'Stats',
    'ScanAndOptimizeWifi',
    'LanConfig',
    'SSIDPassword',
    'SiteSurvey',
    'VoipConfig',
  ],
  // @ts-ignore
  featuresAuthorized: [
    'PausePlay',
    'ScanAndOptimizeWifi',
    'Profile',
    'FirmwareManagement',
    'SiteSurvey',
    'DeviceManagement',
    'Speedtest',
    'SSIDPassword',
    'Traceroute',
    'LanConfig',
    'Ping',
    'Stats',
    'Reboot',
  ],
  subscriberSince: '2023-07-10 09:50:40.066296',
  logRetentionDays: 7,
  trialEnabled: false,
  friendly: true,
  tags: {
    TRIAL: 'false',
  },
  cpeId: '14:A7:2B:CE:84:1E',
  powerRating: {
    temperature: 24.3125,
    voltage: 3.4876,
    biasCurrent: 13.1,
  },
  olt: null,
  manufacturer: null,
  isWanProvisioningEnabled: false,

  hardware: {
    manufacturer: 'netlink',
    band: FREQUENCY_BAND.DUAL,
    id: 'fed2765b653b451494a920d084a71923',
    model: 'XPON+2GE+1POTS+2WIFI',
    version: '4.1',
  },
  wan: WAN_CONFIG_LIST_MOCK,
  lan: LAN_CONFIG_MOCK,
  wlan: WLAN_CONFIG_LIST_MOCK,
  voip: VOIP_CONFIG_MOCK.voip,
};

export const MANUFACTURERS_LIST_MOCK: IOptions[] = [
  { label: 'syrotech', value: 'syrotech' },
  { label: 'secureye', value: 'secureye' },
  { label: 'revotik', value: 'revotik' },
  { label: 'DBC', value: 'DBC' },
  { label: 'electroline', value: 'electroline' },
  { label: 'Ubiqcom', value: 'Ubiqcom' },
  { label: 'digisol', value: 'digisol' },
  { label: 'uniway', value: 'uniway' },
  { label: 'Netlink', value: 'Netlink' },
  { label: 'adopt', value: 'adopt' },
  { label: 'MONU', value: 'MONU' },
  { label: 'technxt', value: 'technxt' },
];

export const MODELS_LIST_MOCK: IOptions[] = [
  {
    key: 'SY-GPON-2010-WADONT',
    label: 'SY-GPON-2010-WADONT',
    value: 'SY-GPON-2010-WADONT',
  },
  { key: 'RVXPON302WAC', label: 'RVXPON302WAC', value: 'RVXPON302WAC' },
  {
    key: 'S-XPON-2010-WDONTA',
    label: 'S-XPON-2010-WDONTA',
    value: 'S-XPON-2010-WDONTA',
  },
  {
    key: 'S-XPON-1110-WDONT',
    label: 'S-XPON-1110-WDONT',
    value: 'S-XPON-1110-WDONT',
  },
  { key: 'HG-323', label: 'HG-323', value: 'HG-323' },
  {
    key: 'SY-GPON-1110-WDONT',
    label: 'SY-GPON-1110-WDONT',
    value: 'SY-GPON-1110-WDONT',
  },
  { key: 'UB5021GVWD', label: 'UB5021GVWD', value: 'UB5021GVWD' },
  { key: 'DBC_HG323RGW', label: 'DBC_HG323RGW', value: 'DBC_HG323RGW' },
  { key: 'RVXPON302W', label: 'RVXPON302W', value: 'RVXPON302W' },
  { key: 'DBC_HG323DAC', label: 'DBC_HG323DAC', value: 'DBC_HG323DAC' },
  { key: 'DG-GR1321', label: 'DG-GR1321', value: 'DG-GR1321' },
  { key: 'HG323DAC', label: 'HG323DAC', value: 'HG323DAC' },
  { key: 'UW-301VPW', label: 'UW-301VPW', value: 'UW-301VPW' },
  { key: 'AD2X-2021', label: 'AD2X-2021', value: 'AD2X-2021' },
  {
    key: '4LAN+1POTS+2WIFI+XPON',
    label: '4LAN+1POTS+2WIFI+XPON',
    value: '4LAN+1POTS+2WIFI+XPON',
  },
  {
    key: 'SY-GPON-1110-WADONT',
    label: 'SY-GPON-1110-WADONT',
    value: 'SY-GPON-1110-WADONT',
  },
  { key: 'UW-323DAC', label: 'UW-323DAC', value: 'UW-323DAC' },
  {
    key: 'TnT-323DAC-GEXPON',
    label: 'TnT-323DAC-GEXPON',
    value: 'TnT-323DAC-GEXPON',
  },
];

export const CONFIGURED_SUBSCRIBER_BY_ID_MOCK: ISubscriberResponse = {
  franchiseeId: null,
  subscriberGroupId: null,
  name: 'Veera',
  phoneNumber: null,
  subscriberId: 'de986423d1a647fb9475d3f7b0436646',
  device: {
    mac: '14:A7:2B:CE:84:1E',
    serialNumber: '1234514A72BCE841E',
    deviceStatus: 'ACTIVE',
    manufacturer: 'Netlink',
    managementProtocol: MANAGEMENT_PROTOCOL.AGENT,
    onlineStatus: OnlineStatus.OFFLINE,
    upTime: null,
    lastSeenTime: '2024-03-09T06:31:00Z',
    wanIp: '192.168.1.67',
    hardwareId: 'fed2765b653b451494a920d084a71923',
    hardwareModel: 'HG323DAC',
    firmwareVersion: 'V2.1.08-221124',
    agentVersion: 'dualBand_v26.0.0-t3',
    isBaseFirmwareUpgradeRequired: null,
    baseFirmwareLink: null,
    isFirmwareUpgradeRequired: null,
    firmwareLink: null,
    cloudEnabled: null,
    ports: {
      ethernet: [
        {
          port: 1,
          type: 'lan',
          active: false,
          disabled: false,
        },
        {
          port: 2,
          type: 'lan',
          active: false,
          disabled: false,
        },
      ],
      fxs: [],
      gpon: {
        active: false,
        disabled: false,
      },
    },
    accessType: ACCESS_TYPE_DISPLAY_NAME.EPON,
    hardwareVersion: '4.1',
    wlans: [],
    band: ['2.4 GHz', '5.0 GHz'],
    wifiEnabled: true,
    agentDetails: {
      mode: FIRMWARE_LAUNCHER_MODE.LAUNCHER,
      launcherVersion: 'fii_netlink_hg323dac_4.1_v26.0.0-t1',
      fiiCoreVersion: 'dualBand_v26.0.0-t3',
      agentVersion: null,
    },
  },
  onboardingState: OnboardingState.PROVISIONED,
  address: {
    addressLine1: 'Chennai',
    areaCode: '600001',
  },
  hardware: {
    manufacturer: 'netlink',
    band: FREQUENCY_BAND.DUAL,
    id: 'fed2765b653b451494a920d084a71923',
    model: 'XPON+2GE+1POTS+2WIFI',
    version: '4.1',
  },
  wan: WAN_CONFIG_LIST_MOCK,
  lan: null,
  wlan: [
    {
      ssid: 'Multimode 2.4',
      band: '2.4 GHz',
    },
  ],
  subscriberSince: '',
  logRetentionDays: 0,
  olt: null,
};

export const PENDING_SUBSCRIBER_BY_ID_MOCK: ISubscriberResponse = {
  franchiseeId: null,
  name: 'Priyan',
  phoneNumber: null,
  subscriberId: '6a5c0c27aa4441d4b5ecab92d658335b',
  device: null,
  onboardingState: OnboardingState.PENDING,
  address: {
    addressLine1: 'Chennai',
    areaCode: '600001',
  },
  hardware: {
    manufacturer: 'netlink',
    band: FREQUENCY_BAND.DUAL,
    id: 'fed2765b653b451494a920d084a71923',
    model: 'XPON+2GE+1POTS+2WIFI',
    version: '4.1',
  },
  wan: WAN_CONFIG_LIST_MOCK?.map((v) => {
    return { ...v, id: null };
  }),
  lan: null,
  wlan: [
    {
      ssid: 'Multimode 2.4',
      band: '2.4 GHz',
    },
  ],
  subscriberSince: '',
  logRetentionDays: 0,
  olt: null,
};
