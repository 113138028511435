import { ActionListStateReducer } from './slices/actionListSlice';
import { AlarmsReducer } from './slices/alarmListSlice';
import { AuthReducer } from './slices/authSlice';
import { CPDashboardReducer } from './slices/dashboards/cpSlice';
import { CommandGroupReducer } from './slices/commandGroupSlice';
import { CommonReducer } from './slices/commonSlice';
import { DeviceStateReducer } from './slices/deviceSlice';
import { DistributorDashboardReducer } from './slices/dashboards/distributorSlice';
import { FirmwareReducer } from './slices/firmwareSlice';
import { FranchiseeDashboardReducer } from './slices/dashboards/franchiseeSlice';
import { FranchiseeListSliceReducer } from './slices/franchiseeListSlice';
import { ISPDashboardReducer } from './slices/dashboards/ispSlice';
import { ISPListSliceReducer } from './slices/ispListSlice';
import { OLTDashboardReducer } from './slices/dashboards/oltDashboardSlice';
import { OLTFormSliceReducer } from './slices/oltFormSlice';
import { OLTReducer } from './slices/oltListSlice';
import { OperatorListReducer } from './slices/operatorListSlice';
import { ReportsReducer } from './slices/reportsSlice';
import { ResellerDashboardReducer } from './slices/dashboards/resellerDashboardSlice';
import { SMSTransactionsReducer } from './slices/smsTransactionsSlice';
import { SubscriberDashboardReducer } from './slices/dashboards/subscriberSlice';
import { SubscriberFormReducers } from './slices/subscriberFormSlice';
import { SubscriptionReducer } from './slices/subscriptionSlice';
import { TicketReducer } from './slices/ticketSlice';
import { TrialsStateReducer } from './slices/trialSlice';
import { UserSliceReducer } from './slices/usersSlice';
import { configureStore } from '@reduxjs/toolkit';
import { dashboardStatsReducer } from './slices/stats/dashboardStatsSlice';
import { oltScheduleReducer } from './slices/oltScheduleSlice';
import { receiptReducer } from './slices/receiptSlice';
import { resellerReducer } from './slices/resellerListSlice';
import { subscriberGroupReducer } from './slices/subscriberGroupSlice';
import { subscriberListReducer } from './slices/subscriberListSlice';
import { unsupportedHardwareReducer } from './slices/unsupportedHardwareListSlice';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    alarmState: AlarmsReducer,
    auth: AuthReducer,
    userState: UserSliceReducer,
    common: CommonReducer,
    cpDashboard: CPDashboardReducer,
    ispDashboard: ISPDashboardReducer,
    ispListState: ISPListSliceReducer,
    franchiseeDashboard: FranchiseeDashboardReducer,
    franchiseeListState: FranchiseeListSliceReducer,
    subscriberDashboard: SubscriberDashboardReducer,
    smsTransactionsState: SMSTransactionsReducer,
    tickets: TicketReducer,
    firmware: FirmwareReducer,
    distributorDashboard: DistributorDashboardReducer,
    trialState: TrialsStateReducer,
    receiptListScreen: receiptReducer,
    commandGroup: CommandGroupReducer,
    dashboardStats: dashboardStatsReducer,
    subscribersListState: subscriberListReducer,
    subscriberFormState: SubscriberFormReducers,
    subscriberGroupState: subscriberGroupReducer,
    OLTListState: OLTReducer,
    OLTDashboardState: OLTDashboardReducer,
    deviceState: DeviceStateReducer,
    reportState: ReportsReducer,
    unsupportedHardwareListState: unsupportedHardwareReducer,
    resellerListState: resellerReducer,
    resellerDashboard: ResellerDashboardReducer,
    actionListState: ActionListStateReducer,
    subscriptionState: SubscriptionReducer,
    operatorListState: OperatorListReducer,
    oltScheduleState: oltScheduleReducer,
    oltFormState: OLTFormSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
