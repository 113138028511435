import {
  IIpReachability,
  IOLTBasicDetailsForm,
  IOLTConfigurationForm,
  IOLTFormReduxState,
  IOLTTelnetReachabilityStatus,
  IPendingOLTData,
  ISNMPReachabilityStatus,
  OLTFormTabs,
} from 'store/types/olt.types';

import { createSlice } from '@reduxjs/toolkit';

const initialState: IOLTFormReduxState = {
  ipReachabilityStatus: {} as IIpReachability,
  telnetReachabilityData: {} as IOLTTelnetReachabilityStatus,
  SNMPReachabilityStatus: {} as ISNMPReachabilityStatus,
  oltBasicDetailsForm: {} as IOLTBasicDetailsForm,
  oltConfigurationForm: {} as IOLTConfigurationForm,
  pendingOLTData: {} as IPendingOLTData,
  currentStepId: 0,
  currentTabId: OLTFormTabs.BASIC_DETAILS,
  formValidationListenerKey: null,
  submitRequestError: '',
  isSbmitLoading: false,
  isNextButtonDisabled: false,
};

export const OLTFormSlice = createSlice({
  name: 'OLTFormSlice',
  initialState,
  reducers: {
    resetToInit: () => initialState,

    setIpReachabilityStatus: (
      state,
      { payload }: { payload: IIpReachability },
    ) => {
      state.ipReachabilityStatus = payload;
    },

    setTelnetReachabilityState: (
      state,
      { payload }: { payload: IOLTTelnetReachabilityStatus },
    ) => {
      state.telnetReachabilityData = payload;
    },

    setSNMPReachabilityState: (
      state,
      { payload }: { payload: ISNMPReachabilityStatus },
    ) => {
      state.SNMPReachabilityStatus = payload;
    },
    setCurrentStepId: (state, { payload }: { payload: number }) => {
      state.formValidationListenerKey = null;
      state.submitRequestError = '';
      state.currentStepId = payload;
      state.isSbmitLoading = false;
    },
    setCurrentTabId: (state, { payload }: { payload: string }) => {
      state.formValidationListenerKey = null;
      state.submitRequestError = '';
      state.currentTabId = payload;
      state.isSbmitLoading = false;
      state.isNextButtonDisabled = false;
    },
    setOLTFormValues: (
      state,
      {
        payload,
      }: {
        payload: {
          formName: OLTFormTabs;
          values: any;
        };
      },
    ) => {
      if (payload?.formName === OLTFormTabs.BASIC_DETAILS) {
        state.oltBasicDetailsForm = payload.values;
      } else if (payload?.formName === OLTFormTabs.CONFIGURATION) {
        state.oltConfigurationForm = payload.values;
      }
    },
    setFormValidationListenerKey: (state) => {
      state.formValidationListenerKey = Math.random();
    },

    setForSubmitRequestError: (state, { payload }: { payload: string }) => {
      state.submitRequestError = payload;
    },

    setIsNextButtonDisabled: (state, { payload }: { payload: boolean }) => {
      state.isNextButtonDisabled = payload;
    },

    setIsFormSubmitLoading: (state, { payload }: { payload: boolean }) => {
      state.isSbmitLoading = payload;
    },

    setPendingOLTData: (state, { payload }: { payload: IPendingOLTData }) => {
      state.pendingOLTData = payload;
    },
  },
});

export const OLTFormAction = {
  ...OLTFormSlice.actions,
};
export const OLTFormSliceReducer = OLTFormSlice.reducer;
