import { MenuProps, PopoverProps } from 'antd';

import { IContainerSettings } from '@amcharts/amcharts5';
import { IHierarchySettings } from '@amcharts/amcharts5/hierarchy';
import { ReactNode } from 'react';

export interface ISplitterData {
  serialNumber: string;
  type: SPLITTER_TYPE | null;
  capacity: number | null;
  friendlyName: string;
  geoLabel: string;
  geoCoordinates: ISplitterGeoCoordinates;
  isChildSplitterAllowed: boolean | null;
  lockNewConnection: boolean | null;
  parent: ISplitterParent;
}
export interface ISplitterGeoCoordinates {
  latitude: string;
  longitude: string;
}
export interface ISplitterParent {
  type: SPLITTER_PARENT_TYPE | null;
  splitter?: ISplitterParentSplitterData;
  olt?: ISplitterParentOLTData;
  availablePorts?: number[];
}

export interface ISplitterParentSplitterData {
  id: string;
  port: string | null;
  name?: string;
}
export interface ISplitterParentOLTData {
  id?: string | null;
  mac?: string;
  port?: string | null;
  ipAddress?: string;
  label?: string;
}
export interface ISplitterFormDropdownValues {
  splitterType: string[];
  splitterCapacity: number[];
}
export interface ISplitterList {
  splitterId: string;
  name: string;
  ports: string[] | number[];
}
export interface ISplitterChartData {
  id: string;
  type: TREE_CHART_NODE_TYPE;
  title?: string;
  name?: string;
  isActive?: boolean;
  reasonCode?: string | null;
  port?: string;
  capacity?: number | null;
  availablePorts?: number[] | null;
  portsUsed?: number[] | null;
  subscriberCount?: number | null;
  childSplitterCount?: number | null;
  mac?: string | null;
  portIndex?: string | null;
  path?: ISplitterChartData[];
  isChildSplitterAllowed?: boolean | null;
}

export interface ISplitterGetQueryParams {
  parentHierarchy?: string;
}
export interface INetworkHierarchyPathParams {
  oltMac: string;
}

export interface ISplitterDropdown {
  splitterId: string;
  name: string;
  ports: string[] | number[];
}

export enum GET_SPLITTER_TYPE_QUERY_PARAM {
  ALL = 'ALL',
  AVAILABLE_PORTS_PER_SPLITTER = 'AVAILABLE_PORTS_PER_SPLITTER',
}

//  #region - Enum and Constants
export enum SPLITTER_TYPE {
  FBT = 'FBT',
  PLC = 'PLC',
}

export enum SPLITTER_PARENT_TYPE {
  SPLITTER = 'SPLITTER',
  OLT = 'OLT',
}

export enum TREE_CHART_NODE_TYPE {
  OLT = 'OLT',
  SPLITTER = 'SPLITTER',
  SUBSCRIBER = 'SUBSCRIBER',
}
//  #endregion - Enum and Constants

//  #region - Tree Chart

export interface IAMChart5TreeProps {
  chartDetails: IAMChart5ChartDetails;
  width: string | number;
  height: string | number;
  chartConfig?: IContainerSettings;
  treeConfig?: IHierarchySettings;
  rightClickOptions?: MenuProps['items'];
  setSelectedNodeDetails?: (value: any) => void;
  setSelectedTooltipNodeDetails?: (value: any) => void;
  tooltip?: PopoverProps | null;
  extras?: ReactNode[] | null;
}

export interface IAMChart5ChartDetails {
  chartId: string;
  loading?: boolean;
  error?: string | null;
  data: ISplitterChartData[];
  handleReload?: () => void;
}

//  #endregion - Tree Chart
