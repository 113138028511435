import {
  API_VERSIONS,
  TAPI_VERSION,
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'apiUtils/axiosRequest';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  IGetOLTPendingListParam,
  IOLTBasicDetailsForm,
  IOLTConfigurationForm,
  IRequestParams,
} from 'store/types/olt.types';
import {
  ISplitterData,
  ISplitterGetQueryParams,
} from 'store/types/splitter.types';
import { createAxiosInstance, formatURL } from 'apiUtils/api';

import { API_END_POINTS } from 'apiUtils/urls';
import { IFranchiseeFormData } from 'store/types/franchisee.types';
import { IManagementEnityIdPathParam } from 'store/types';

const OPERATOR_BASE_URL = process.env.API_OPERATOR?.replace('v1/', '');
const OperatorInstance = createAxiosInstance(OPERATOR_BASE_URL);

interface IDeleteConfigureOLTParams {
  managementEntityId: string;
  oltMac: string;
}
interface IFranchiseeByIDParams {
  franchiseeId: string;
}
interface IISPByIDParams {
  ispId: string;
}

export interface IOperatorService {
  // #region - Franchisee
  GetFranchiseeList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetFranchiseeById: (params: IFranchiseeByIDParams) => Promise<AxiosResponse>;
  UpdateFranchisee: (
    params: IFranchiseeByIDParams,
    data: IFranchiseeFormData,
  ) => Promise<AxiosResponse>;
  // #endregion - Franchisee
  // #region - ISP
  GetISPList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetISPById: (params: IISPByIDParams) => Promise<AxiosResponse>;
  // #endregion - ISP
  GetManagementEntity: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetManagementEntityById: (
    params: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  GetOLTOperatorsList: (config?: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetResellerList: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetOperatorStatsCount: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  GetOperatorSubscriptionStats: (
    config: AxiosRequestConfig,
  ) => Promise<AxiosResponse>;
  VerifyManagementEntityDelete: (
    params: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  DeleteManagementEntity: (
    params: IManagementEnityIdPathParam,
  ) => Promise<AxiosResponse>;
  getOLTPendingList: (
    params: IGetOLTPendingListParam,
  ) => Promise<AxiosResponse>;
  DeletePendingOLTs: (config: AxiosRequestConfig) => Promise<AxiosResponse>;
  DeleteConfigureOLT: (
    pathParam: IDeleteConfigureOLTParams,
  ) => Promise<AxiosResponse>;
  getSupportedManufacturer: () => Promise<AxiosResponse>;
  createOLTBasicDetails: (
    params: IRequestParams,
    payload: IOLTBasicDetailsForm,
  ) => Promise<AxiosResponse>;
  updateOLTBasicDetails: (
    params: IRequestParams,
    payload: IOLTBasicDetailsForm,
  ) => Promise<AxiosResponse>;
  getPendingOLTByIpAddress: (params: IRequestParams) => Promise<AxiosResponse>;
  migrateToConfigured: (params: IRequestParams) => Promise<AxiosResponse>;
  configureOLT: (payload: IOLTConfigurationForm) => Promise<AxiosResponse>;
  GetSplittersList: () => Promise<AxiosResponse>;
  GetSplitterById: (
    splitterId: string,
    params: ISplitterGetQueryParams,
  ) => Promise<AxiosResponse>;
  GetSplitterDropdownValues: () => Promise<AxiosResponse>;
  CreateSplitter: (payload: ISplitterData) => Promise<AxiosResponse>;
  UpdateSplitter: (
    splitterId: string,
    payload: ISplitterData,
  ) => Promise<AxiosResponse>;
  DeleteSplitter: (splitterId: string) => Promise<AxiosResponse>;
}

export const OperatorService: { [key in TAPI_VERSION]: IOperatorService } =
  Object.keys(API_VERSIONS)?.reduce((res: any, el: any) => {
    const version = API_VERSIONS[el as keyof typeof API_VERSIONS];

    return {
      ...res,
      [el]: {
        //  #region - Franchisee
        GetFranchiseeList: async (config: AxiosRequestConfig) => {
          const params = {
            ...config?.params,
            showActiveME: 'true',
          };
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.franchisee}`,
            { ...config, params },
          );
        },
        GetFranchiseeById: async (params: IFranchiseeByIDParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.franchiseeByID, {
              franchiseeId: params?.franchiseeId || '',
            })}`,
          );
        },
        UpdateFranchisee: async (
          params: IFranchiseeByIDParams,
          data: IFranchiseeFormData,
        ) => {
          return await putRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.franchiseeByID, {
              franchiseeId: params?.franchiseeId || '',
            })}`,
            data,
          );
        },
        //  #endregion - Franchisee

        // #region - ISP
        GetISPList: async (config: AxiosRequestConfig) => {
          const params = {
            ...config?.params,
            showActiveME: 'true',
          };
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.ISPS}`,
            { ...config, params },
          );
        },
        GetISPById: async (params: IISPByIDParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.ISPByID, {
              ispId: params?.ispId || '',
            })}`,
          );
        },
        // #endregion - ISP

        //#region - Dropdown
        GetManagementEntity: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.managementEntity}`,
            config,
          );
        },
        GetManagementEntityById: async (
          params: IManagementEnityIdPathParam,
        ) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.managementEntityById,
              params,
            )}`,
          );
        },
        GetOLTOperatorsList: async (config?: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.OLT_ManagementEntity}`,
            config,
          );
        },
        GetResellerList: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.resellers}`,
            config,
          );
        },
        //#endregion - Dropdown

        GetOperatorStatsCount: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.statsCounts}`,
            config,
          );
        },
        GetOperatorSubscriptionStats: async (config: AxiosRequestConfig) => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.subscriptionStats}`,
            config,
          );
        },
        VerifyManagementEntityDelete: async (
          params: IManagementEnityIdPathParam,
        ) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.verify_ME_delete,
              params,
            )}`,
          );
        },
        DeleteManagementEntity: async (params: IManagementEnityIdPathParam) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.managementEntityById,
              params,
            )}`,
          );
        },

        //  #region -OLT List
        getOLTPendingList: async (params: IGetOLTPendingListParam) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.oltPendingList,
              params?.path,
            )}`,
            { params: params?.query },
          );
        },
        DeletePendingOLTs: async (config: AxiosRequestConfig) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.pendingOLTs}`,
            config,
          );
        },
        DeleteConfigureOLT: async (pathParam: IDeleteConfigureOLTParams) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.OLTByMac,
              pathParam,
            )}`,
          );
        },

        //  #endregion - OLT List

        //  #region - OLT Form
        getSupportedManufacturer: async () => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.olt_supportedManufacturer}`,
          );
        },

        createOLTBasicDetails: async (
          params: IRequestParams,
          payload: IOLTBasicDetailsForm,
        ) => {
          return await postRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.oltInfo,
              params?.path,
            )}`,
            payload,
          );
        },

        updateOLTBasicDetails: async (
          params: IRequestParams,
          payload: IOLTBasicDetailsForm,
        ) => {
          return await putRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.oltInfoByIpAddress,
              params?.path,
            )}`,
            payload,
          );
        },

        getPendingOLTByIpAddress: async (params: IRequestParams) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.pendingOLTByIpAddress,
              params?.path,
            )}`,
          );
        },

        migrateToConfigured: async (params: IRequestParams) => {
          return await postRequest(
            OperatorInstance,
            `${version}${formatURL(
              API_END_POINTS.OPERATORS.migrateToConfigured,
              params?.path,
            )}`,
            {},
          );
        },

        configureOLT: async (payload: IOLTConfigurationForm) => {
          return await postRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OLT_GATEWAY.oltConfiguration}`,
            payload,
          );
        },
        // #endregion - OLT Form

        //  #region - Splitter
        GetSplittersList: async () => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.splitters}`,
          );
        },
        GetSplitterById: async (splitterId: string) => {
          return await getRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.splitterById, {
              splitterId,
            })}`,
          );
        },
        GetSplitterDropdownValues: async () => {
          return await getRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.splitterDropdownData}`,
          );
        },
        CreateSplitter: async (payload: ISplitterData) => {
          return await postRequest(
            OperatorInstance,
            `${version}${API_END_POINTS.OPERATORS.splitters}`,
            payload,
          );
        },
        UpdateSplitter: async (splitterId: string, payload: ISplitterData) => {
          return await patchRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.splitterById, {
              splitterId,
            })}`,
            payload,
          );
        },
        DeleteSplitter: async (splitterId: string) => {
          return await deleteRequest(
            OperatorInstance,
            `${version}${formatURL(API_END_POINTS.OPERATORS.splitterById, {
              splitterId,
            })}`,
          );
        },
        //  #endregion - Splitter
      } as IOperatorService,
    };
  }, {});
