export const API_END_POINTS = {
  AUTH: {
    LOGIN_INIT: '/bsec/login/init',
    SEND_OTP: '/bsec/login/send-otp',
    LOGIN_VERIFY_OTP: '/bsec/login/verify-otp',
    LOGIN_VERIFY_PASSWORD: '/bsec/login/verify',
    logout: '/bsec/logout',
    exchangeKey: '/bsec/exch-key',

    // Forgot Password
    forgotPasswordInit: '/forgot-password/init',
    forgotPasswordRequestOTP: '/forgot-password/send-otp',
    forgotPasswordVerifyOTP: '/forgot-password/verify-otp',
    forgotPassword: '/forgot-password',
    updatePassword: '/update-password',
  },
  HARDWARES: {
    markAsSupported: '/unsupported-hardware/{hardwareId}/mark-as-supported',
    manufacturers: '/manufacturers',
    hardwareModels: '/hardware/models',
    encryptionModes: '/hardware/{hardwareId}/encryption-modes',
    voipPortsSupported: '/hardwares/{hardwareId}',
  },
  OPERATORS: {
    resellers: '/reseller',
    resellerById: '/reseller/{resellerId}',
    channelPartners: '/partners/',
    channelPartnerByID: '/partners/{partnerId}',
    distributors: '/distributors/',
    distributorByID: '/distributors/{distributorId}',
    ISPS: '/isps/',
    ISPByID: '/isps/{ispId}',
    verify_ME_delete: '/management-entities/{managementEntityId}/allow-delete',
    franchisee: '/franchisee/',
    franchiseeByID: '/franchisee/{franchiseeId}',
    firmware_policies: '/firmware/download-policies',
    statsCounts: '/dashboard/stats',
    distributorStatsCounts: '/dashboard/distributor-stats',
    users: '/users/',
    userCreate: '/users',
    roles: '/roles/',
    userByID: '/users/{userId}',
    managementEntity: '/management-entities',
    managementEntityById: '/management-entities/{managementEntityId}',
    trial: 'management-entities/{managementEntityId}/trials',
    whoAmI: '/whoami/',
    distributor_associated_isps:
      '/distributors/{distributorId}/associated-isps',
    associated_isps: '/franchisee/{franchiseeId}/associated-isps',
    downloadTemplate: '/subscriber-template/download',
    createOLT: '/{managementEntityId}/olt',
    updateTelemetryConfig:
      '/{managementEntityId}/olt/{oltMac}/telemetry-config',
    OLTByMac: '/{managementEntityId}/olt/{oltMac}',
    pendingOLTs: '/pending-olts',
    approvedProducts: '/reseller/approved-products',
    subscriptionStats: '/me-stats/subscription',
    markOnboardingComplete:
      '/management-entities/{managementEntityId}/mark-onboarding-completed',
    cancelDiscovery: '/{managementEntityId}/olt/{oltMac}/discovery/cancel',
    OLT_ManagementEntity: '/olt/management-entities',
    olt_access_type: '/olt/access-type',
    olt_supportedManufacturer: '/olt/supported-manufacturer',
    olt_supportedModel: '/olt/supported-model',
    OLTBridgeStatus: '/{managementEntityId}/olt-bridge-status',
    onuTelemetryStatus:
      '/management-entities/telemetry/onu/{managementEntityId}/all',
    oltTelemetryStatus:
      '/management-entities/telemetry/olt/{managementEntityId}/all',
    //  Susbcriber Group
    subscriberGroup: '/subscriber-group',
    subscriberGroupById: '/subscriber-group/{subscriberGroupId}',

    //OLT Schedule
    oltScheduleList: '/olts/scheduled-tasks',
    oltScheduleHistory: '/olts/transactions/history',
    OLTScheduleByTransactionId: '/olts/discovery/{transactionId}',
    createOLTSchedule: '/olts/discovery/schedule',

    oltInfo: '/{managementEntityId}/olt-info',
    oltInfoByIpAddress: '/{managementEntityId}/olt-info/{ipAddress}',
    oltPendingList: '/{managementEntityId}/pending-olts',
    pendingOLTByIpAddress: '/{managementEntityId}/pending-olts/{ipAddress}',
    migrateToConfigured:
      '/{managementEntityId}/pending-olts/{ipAddress}/migrate-to-configured',
    // Splitter
    splitters: '/splitters',
    splitterById: '/splitters/{splitterId}',
    splitterDropdownData: '/splitters/required-data',
  },
  VIZ: {
    subscriberSearch: '/search',
    subscribers: '/subscribers',
    subscribersById: '/subscribers/{subscriberId}',
    operatorSearch: '/management-entities/search',
    opticalStrength: '/subscribers/{subscriberId}/devices/{mac}/optical-stats',
    subscriberStats: '/subscriber-stats',

    channelSwitch:
      '/subscribers/{subscriberId}/devices/{deviceMac}/channel-switch-history',
    wifiThroughput:
      '/subscribers/{subscriberId}/devices/{deviceMac}/wifi-throughput-history',
    rssiTrends:
      '/subscribers/{subscriberId}/clients/{clientMac}/rssi-history/{interval}',
    devicethroughput:
      '/subscribers/{subscriberId}/clients/{clientMac}/throughput-history',
    profiles: '/subscribers/{subscriberId}/profiles',
    filters: '/filters',
    logFilters: '/logs/filters',
    logs: '/subscribers/{subscriberId}/devices/{mac}/logs',
    logsHistory: '/subscribers/{subscriberId}/devices/{mac}/logs-history',
    hardware_by_id: '/hardware-by-id',
    upComing: '/command-groups/upcoming',
    upComingById: '/command-groups/upcoming/{transactionId}',
    history: '/command-groups/history',
    trialDashboard: '/trials-dashboard',
    trialByAge: '/trials/trialByAge',
    actionStats: '/action-stats',
    subscriberStatsByOnlineOffline: '/visualization/online-offline-stats',
    subscriberStatsByPoorSignal: '/visualization/poor-optical-signal',
    subscriberStatsByPending: '/visualization/pending-stats',
    poorOpticalStatsByOLT: '/visualization/poor-optical-signal-by-olt',
    olt: '/devices/{managementEntityId}/olts',
    oltByMac: '/devices/{managementEntityId}/olts/{oltMac}',
    ontStats: '/devices/{managementEntityId}/ont-stats',
    offlineSubscriberByDuration:
      '/visualization/{managementEntityId}/offline-subscribers-count-stats',
    deviceStats: '/stats/olts/device',
    deviceByProtocolCountStats: '/stats',
    deviceStatusEvent:
      '/devices/olt/{oltMac}/ont/{ontNumber}/port/{portNumber}',
    pendingDeviceFilters: '/pending-device-filters',

    //  OLT Onboarding - Devices Menu
    oltOnboardingStats: '/stats/{managementEntityId}/olts',
    oltStatsByMacAndPort:
      '/stats/{managementEntityId}/olts/{oltMac}/ports/{oltPort} ',
    oltList: '/info/{managementEntityId}/olts',
    oltPendingActivities: '/transactions/{managementEntityId}/olts',
    deviceStatusByOlt: '/onboard/{managementEntityId}/olts',
    oltPendingActivitiesByMacAndPort:
      '/transactions/{managementEntityId}/olts/{oltMac}/ports/{oltPort}',
    enrolledList: '/enrolled/{managementEntityId}/olts',
    assignedList: '/assigned/{managementEntityId}/olts',
    pendingList: '/pending/{managementEntityId}/onts',
    hardwareModelFilters:
      '/{managementEntityId}/olts/{oltMac}/ports/{oltPort}/filters',
    oltHistory: '/{managementEntityId}/olts/transactions/history',
    devicesCountByOLT: '/olts/devices/count',
    devicesCountByOUI: '/olts/{deviceOnboardingCategory}/devices/count',
    devicesCountByManufacturerStats:
      '/olts/{deviceOnboardingCategory}/devices/count-by-manufacturer',

    // OLT Dashboard
    oltDashboardCardStats: '/{managementEntityId}/olts/{oltMac}/card-stats',
    oltDashboardDevicesStats: '/{managementEntityId}/olts/{oltMac}/ont-stats',
    uplinkTraffic: '/{managementEntityId}/olts/{oltMac}/uplink-ports',
    ponTraffic: '/{managementEntityId}/olts/{oltMac}/pon-ports',
    ontList: '/{managementEntityId}/olts/{oltMac}/ont-list',
    oltOpticalStats: '/{managementEntityId}/olts/{oltMac}/optical-stats',

    //  Reports
    reportTypes: '/report-types',
    timeRangeOptions: '/reports/time-range-options',
    listOlts: '/olts',
    offlineDurationRange: '/offline-duration-ranges',

    //  Unsupported Hardwares
    unsupportedHardwareList: '/hardware/unsupported',

    //  Subscription
    pricing: '/{managementEntityId}/pricing',

    //Reseller
    leads: '/reseller/leads',
    leadsStats: '/reseller/leads-stats',
    subscriptionPlanCount: '/reseller/active-subscription-package/count',
    paidSubscriptionExpiry: '/reseller/paid-subscription-expiry/count',
    subscriptionExpiredCount: '/reseller/expired-subscription/count',
    trialByStatus: '/reseller/trial-by-status/count',
    operators: '/reseller/operators',
    loginStats: '/reseller/management-entities/login-stats',

    //  Subscriber Group
    subscriberGroupList: '/subscriber-groups',

    // OLT Schedule
    oltScheduleDuration: '/olts/discovery-recurrance',

    //WAN Create
    serviceMode: '/hardware/service-modes',
    firmwarePending: '/firmware/pending/count/{managementEntityId}',
    checkFirmware: '/subscribers/{subscriberId}/check-fw-ver',
    hardwareSupportedValues:
      '/manufacturer/{manufacturer}/hardware/{hardwareModel}/supported-value',

    // Stats
    configurationPendingStats: '/subscribers/configuration-pending',
    offlineReasonCodeStats: '/{managementEntityId}/stats/offline-reason-code',
    subscriberId: '/subscriber-id',

    //  Splitter
    splitterStats: '/network-path/branch/{id}', // id - Splitter Id (or) Subscriber Id
    networkHierarchy: '/olt/{oltMac}/network-hierarchy',
  },
  OLT_ONBOARDING: {
    subscribers: '/subscribers',
    subscribersById: '/subscribers/{subscriberId}',
    networkDetails: '/subscribers/{subscriberId}/network-details',
    findOlt: '/subscribers/{subscriberId}/device/{mac}/find-olt',

    onboardOlt: '/bulk-enrollment/{managementEntityId}',
    oltPendingTransactionStatus: '/transactions/{transactionId}/status',
    retry: '/transactions/{transactionId}/retry',
    retryConfirm: '/transactions/{transactionId}/retry/confirm',
    migrationStats: '/stats/transactions/{transactionId}/migration-activity',
    subscribersStatsByOlt: '/{managementEntityId}/olts/{oltMac}/subscribers',
    transaction: '/transaction/{transactionId}',
    unassignDevice: '/subscribers/{subscriberId}/unassign-device',
  },
  OLT_GATEWAY: {
    discoverByOltMac: '/olt/discover/{managementEntityId}/olts/{oltMac}',
    discoverByOltPort:
      '/onts/discover/{managementEntityId}/olts/{oltMac}/ports/{oltPort}',
    reboot: '/onts/{ontMac}/reboot',
    initiateReachability: '/olt/{managementEntityId}/initiate-reachability',
    getONUByONT: '/onts/{ontNumber}/olt/{oltMac}/port/{portNumber}',
    oltIpReachability: '/olt/ip-reachability',
    oltConfiguration: '/olt-configuration',
    telnetReachability: '/olt/telnet-reachability',
  },
  SUBSCRIBER: {
    subscribers: '/subscribers',
    subscribersByID: '/subscribers/{subscriberId}',
    hwStats: '/device-stats',
    fwByHwStats: '/firmware-stats',
    clientInfo: '/subscribers/{subscriberId}/devices/{deviceMac}/clientInfo',
    clients: '/subscribers/{subscriberId}/clients',
    wanThroughput:
      '/subscribers/{subscriberId}/devices/{deviceMac}/wan-throughput',
    natsDetails: '/subscribers/{subscriberId}/nats-details',
    reboot: '/subscribers/{subscriberId}/devices/{mac}/reboot/invoke',
    config: '/subscribers/{subscriberId}/devices/{mac}/config',
    pingInvoke: '/subscribers/{subscriberId}/devices/{mac}/ping/invoke',
    pingResult: '/subscribers/{subscriberId}/devices/{mac}/ping',
    traceInvoke: '/subscribers/{subscriberId}/devices/{mac}/trace/invoke',
    traceResult: '/subscribers/{subscriberId}/devices/{mac}/trace',
    speedTestInvoke:
      '/subscribers/{subscriberId}/devices/{mac}/speedtest/invoke',
    speedTestResult: '/subscribers/{subscriberId}/devices/{mac}/speedtest',
    deviceById: 'devices/{cpeId}',
    updateDevice: 'subscribers/{subscriberId}/update-device',
    updateSsidPassword:
      'subscribers/{subscriberId}/devices/{deviceId}/change-ssid-password',
    channelSurvey: '/subscribers/{subscriberId}/devices/{mac}/acs/survey',
    channelSurveyResult:
      '/subscribers/{subscriberId}/devices/{mac}/transactions/{transactionId}',
    channelOptimize: '/subscribers/{subscriberId}/devices/{mac}/acs/do',
    bulkUpload: '/bulk-uploads',
    bulkUploadByID: '/bulk-uploads/{id}',
    bulkReboot: '/command-groups/reboot ',
    bulkRebootById: '/command-groups/reboot/{transactionId}',
    actionResult: '/action-result',
    wifiOptimize:
      '/subscribers/{subscriberId}/devices/{mac}/actions/wifi-optimize',
    clientBlock: '/subscribers/{subscriberId}/clients/_block',
    clientUnBlock: '/subscribers/{subscriberId}/clients/_unblock',
    actions: '/actions',
    publish_Firmware:
      '/subscribers/{subscriberId}/devices/{mac}/publish-firmware',

    wan: '/subscribers/{subscriberId}/devices/{mac}/wan',
    deleteWAN: '/subscribers/{subscriberId}/devices/{mac}/wan/{wanId}',
    lan: '/subscribers/{subscriberId}/devices/{mac}/lan',
    wlan: '/subscribers/{subscriberId}/devices/{mac}/wlans',
    devicePorts: '/device-ports',
    voip: '/subscribers/{subscriberId}/devices/{mac}/voip',
  },
  CONFIG: {
    transactions: '/subscribers/{subscriberId}/transactions',
  },
  TICKETS: {
    tickets: 'tickets/',
    ticketsCategory: '/tickets/categories',
    ticketByID: 'tickets/{ticketId}',
    calls: '/calls',
    callSupport: '/call-support',
  },
  CENTRALISED_LOGGING: {
    transactions: '/transactions',
    templates: '/templates',
  },
  FIRMWARE: {
    installable: '/installable',
    firmwarePost: '/firmware',
    manufacturer: '/manufacturer',
    s3UploadLink: '/firmware-link',
    publish: '/installables/{installableId}/schedule',
    markeAsStable: '/installables/{installableId}/promote',
    supportedOperators: '/supported-operators',
  },
  BETA: {
    stats: '/stats',
    list: '/subscribers',
    schedule: '/schedule',
  },
  TRIAL: {
    signUp: '/signup',
    list: '/trials',
    trialById: '/trials/{trialId}',
    trialByMeId: '/trials/management-entities/{meId}',
    notes: '/trials/{trialId}/notes',
    activateTrial: '/trials/{trialId}/activate',
    resendApplink: '/trials/{trialId}/send-app-details',
    trialFilters: '/trials/_filters',
    exitTrial: '/trials/{trialId}/exit',
    trialConfig: '/trials/config',
  },
  SUBSCRIPTION: {
    markAsComplete: '/subscription/{subscriptionId}/suspend',
    markAsExpired: '/subscription/{subscriptionId}/expire',
    extendSubscription: '/subscription/{subscriptionId}/extend',
    expiryReasonCodes: '/expiry-reason-codes ',
  },
  REPORTS: {
    reports: '/reports',
    generateReport: '/reports/_generate',
    downloadReport: '/reports/{reportId}/download',
  },
  BILLING: {
    receipt: '/receipt',
    summary: '/summary',
    summaryById: '/summary/{summaryId}',
    calculateSummary: '/calculate-summary',
    payWithSummaryId: '/summary/{summaryId}/pay',
    pay: '/pay',
    transactionById: '/payments/transactions/{transactionId}',
    pendingTransaction: '/payments/pending-transactions',
    continuePayment: '/payments/continue',
    abortPayment: '/payments/abort',
  },
  ALERTS: {
    deleteAllAlerts: '/alerts/_delete_all',
    alarmConfig: '/alarms/_config',
    alarmSummary: '/alarms/summary',
    alertStat: '/alerts/stats',
    alertEvents: '/alert-events',
    supportedAlerts: '/supported-alerts',
    affectedSubscribers: '/alarms/affected-subscribers',
  },
  MESSAGE: {
    notifications: '/notifications/register',
  },
  TICKET_MANAGER: {
    tickets: '/tickets',
    ticketById: '/tickets/{transactionId}',
  },
};
