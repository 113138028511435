import '../../styles/Sidebar.less';

import { Badge, BadgeProps, Layout, Menu } from 'antd';
import { CommonActions, RootState, useAppDispatch } from 'store/redux';
import { EntityType, ROLES, TICKET_STATUS } from 'store/types';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  faArrowRightArrowLeft,
  faBell,
  faCalendarAlt,
  faClock,
  faElevator,
  faEnvelope,
  faFileInvoiceDollar,
  faFileLines,
  faHome,
  faHourglassHalf,
  faLayerGroup,
  faMicrochip,
  faPeopleArrows,
  faQuestionCircle,
  faThumbsUp,
  faTools,
  faTriangleExclamation,
  faUser,
  faUsers,
  faUsersRectangle,
} from '@fortawesome/free-solid-svg-icons';

import { API_END_POINTS } from 'apiUtils/urls';
import { EntityTypeIcons } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { ReactComponent as OLTIcon } from '../../styles/images/oltIcon.svg';
import { PERMISSIONS } from 'utils/roles&Permissions';
import { ROUTES } from 'routes/routes';
import { SupportRequester } from 'apiUtils/api';
import { ReactComponent as WifiRouter } from '../../styles/images/router_wifi.svg';
import { hasPermission } from 'components/utilities/RoleBasedComponent';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yellow } from '@ant-design/colors';

interface IMenuItem {
  path?: string;
  title: string;
  icon: React.ReactNode;
  permissionKey: string | string[];
  badge?: BadgeProps;
  mobileTab?: boolean;
  children?: IMenuItem[];
}

const SideBar = () => {
  const { common, auth } = useSelector((state: RootState) => state);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ticketCount, setTicketCount] = useState(0);
  const navLinkRef = useRef('/' as string | undefined);
  const pollTimerRef: MutableRefObject<NodeJS.Timer | undefined> = useRef();
  const isMobileView = common.mobileView;
  const bottomActiveClass = window.document.querySelector(
    '.bottom-tab-menus .ant-menu-item-selected',
  );

  if (bottomActiveClass) {
    bottomActiveClass?.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
    });
  }

  useEffect(() => {
    getTicketsCount();
    return () => {
      clearTimeout(pollTimerRef.current);
    };
  }, [auth.authorized]);

  const getTicketsCount = async () => {
    if (auth.authorized && hasPermission(PERMISSIONS.MENUS.TICKETS)) {
      await SupportRequester.get(API_END_POINTS.TICKETS.tickets, {
        params: {
          assignedTo: [ROLES.FIELD_AGENT, ROLES.SUPPORT_AGENT].includes(
            auth.userInfo.role,
          )
            ? auth.userInfo.id
            : undefined,
          status: [TICKET_STATUS.NEW, TICKET_STATUS.IN_PROGRESS],
          countOnly: true,
        },
      })
        .then((response) => {
          setTicketCount(response.data.total);
        })
        .catch(({ response }) => response);
      pollTimerRef.current = setTimeout(getTicketsCount, 10 * 60000);
    }
  };

  const MENU_ITEMS: IMenuItem[] = [
    {
      path: auth.homeLink as string,
      title: t('Home'),
      icon: <FontAwesomeIcon icon={faHome} color={'inherit'} fontSize={18} />,
      permissionKey: PERMISSIONS.MENUS.HOME,
      mobileTab: true,
    },
    {
      path: '/app/channel-partners',
      title: t('Channel Partners'),
      icon: <FontAwesomeIcon icon={EntityTypeIcons.CHANNEL_PARTNER} />,
      permissionKey: PERMISSIONS.MENUS.CHANNEL_PARTNER,
    },
    {
      path: '/app/resellers',
      title: t('Reseller'),
      icon: <FontAwesomeIcon icon={faPeopleArrows} />,
      permissionKey: PERMISSIONS.MENUS.RESELLER,
    },
    // {
    //   path: '/app/distributors',
    //   title: t('Distributors'),
    //   icon: <FontAwesomeIcon icon={EntityTypeIcons.DISTRIBUTOR} />,
    //   permissionKey: PERMISSIONS.MENUS.DISTRIBUTOR,
    // },
    {
      path: ROUTES.APP.OPERATORS.OPERATORS,
      title: t('Operators'),
      icon: (
        <FontAwesomeIcon icon={faElevator} color={'inherit'} fontSize={18} />
      ),
      permissionKey: PERMISSIONS.MENUS.OPERATORS,
      mobileTab: true,
    },
    {
      path: ROUTES.APP.ISP.ISP,
      title: t('ISPs'),
      icon: <FontAwesomeIcon icon={EntityTypeIcons.ISP} />,
      permissionKey: PERMISSIONS.MENUS.ISP,
    },
    {
      path: ROUTES.APP.FRANCHISEE.FRANCHISEE,
      title: t('Franchisee'),
      icon: <FontAwesomeIcon icon={EntityTypeIcons.FRANCHISEE} />,
      permissionKey: PERMISSIONS.MENUS.FRANCHISEE,
      mobileTab: auth?.userInfo?.managementEntity?.type === EntityType.ISP,
    },

    {
      path: '/app/trials',
      title: t('Trials'),
      icon: <FontAwesomeIcon icon={faHourglassHalf} />,
      permissionKey: PERMISSIONS.MENUS.TRIALS,
    },
    {
      path: '/app/subscribers',
      title: t('Subscribers'),
      icon: <FontAwesomeIcon icon={faUsers} />,
      permissionKey: PERMISSIONS.MENUS.SUBSCRIBERS,
      mobileTab: true,
    },
    {
      path: '/app/subscriber-group',
      title: t('Subscriber Group'),
      icon: <FontAwesomeIcon icon={faUsersRectangle} />,
      permissionKey: PERMISSIONS.MENUS.SUBSCRIBER_GROUP,
    },
    {
      path: '/app/friendly',
      title: t('Beta Group'),
      icon: <FontAwesomeIcon icon={faThumbsUp} />,
      permissionKey: PERMISSIONS.MENUS.BETA_GROUP,
      badge: common.betaWaning
        ? {
            children: (
              <FontAwesomeIcon icon={faTriangleExclamation} color={yellow[6]} />
            ),
          }
        : undefined,
    },
    {
      path: ROUTES.APP.OLT.OLT,
      title: t('OLT'),
      icon: <OLTIcon className={'svg-icon'} />,
      permissionKey: PERMISSIONS.MENUS.OLT,
    },
    {
      path: ROUTES.APP.DEVICES.KEY,
      title: t('Devices'),
      icon: <WifiRouter className={'svg-icon'} />,
      permissionKey: PERMISSIONS.MENUS.DEVICES,
    },
    {
      path: '/app/unsupported-hardware',
      title: t('Unsupported Hardware'),
      icon: <FontAwesomeIcon icon={faQuestionCircle} />,
      permissionKey: PERMISSIONS.MENUS.UNSUPPORTED_HARDWARE,
    },
    {
      path: '/app/users',
      title: t('labels.users.users'),
      icon: <FontAwesomeIcon icon={faUser} />,
      permissionKey: PERMISSIONS.MENUS.USERS,
      mobileTab: true,
    },
    {
      path: '/app/tickets/open',
      title: t('Tickets'),
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      permissionKey: PERMISSIONS.MENUS.TICKETS,
      badge: {
        count: ticketCount,
      },
    },
    {
      path: '/app/firmware',
      title: t('Firmware'),
      icon: <FontAwesomeIcon icon={faMicrochip} />,
      permissionKey: PERMISSIONS.MENUS.FIRMWARE,
    },
    {
      path: '/app/action-result',
      title: t('Actions'),
      icon: <FontAwesomeIcon icon={faTools} />,
      permissionKey: PERMISSIONS.MENUS.ACTIONS,
    },
    {
      path: '/app/transactions',
      title: t('Transaction Log'),
      icon: (
        <FontAwesomeIcon
          icon={faArrowRightArrowLeft}
          color={'inherit'}
          fontSize={18}
        />
      ),
      permissionKey: PERMISSIONS.MENUS.TRANSACTION_LOGS,
    },
    {
      title: t('Schedule'),
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
      permissionKey: [
        PERMISSIONS.MENUS.BULK_ACTIONS,
        PERMISSIONS.MENUS.OLT_SCHEDULE,
      ],
      children: [
        {
          path: '/app/command-groups',
          title: t('Bulk Actions'),
          icon: <FontAwesomeIcon icon={faLayerGroup} />,
          permissionKey: PERMISSIONS.MENUS.BULK_ACTIONS,
        },
        {
          path: '/app/olt-schedule',
          title: t('labels.schedule.olt.oltSchedule'),
          icon: <FontAwesomeIcon icon={faClock} />,
          permissionKey: PERMISSIONS.MENUS.OLT_SCHEDULE,
        },
      ],
    },
    {
      path: '/app/payments',
      title: t('Payments'),
      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
      permissionKey: PERMISSIONS.MENUS.PAYMENTS,
    },
    {
      path: ROUTES.APP.REPORTS.REPORTS,
      title: t('Reports'),
      icon: <FontAwesomeIcon icon={faFileLines} />,
      permissionKey: PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REPORTS.KEY,
    },
    {
      path: '/app/alerts',
      title: t('Alarms'),
      icon: <FontAwesomeIcon icon={faBell} />,
      permissionKey:
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.KEY,
    },
  ];

  const renderNavigationLink = (menuItem: IMenuItem) => {
    return (
      <NavLink
        to={menuItem?.path || ''}
        className={`sideBarNavLink ${
          !menuItem?.path ? 'pointer-event-none' : ''
        }`}
        style={({ isActive }) => {
          if (isActive) {
            navLinkRef.current = menuItem?.path;
          }
          return {};
        }}
        onClick={() =>
          !common.sideBarCollapsed && dispatch(CommonActions.toggleSideBar())
        }
      >
        <span className={'menu-title-row-container'}>
          {menuItem?.title}
          {!common.sideBarCollapsed && (
            <Badge {...menuItem?.badge} className={'badge'} />
          )}
        </span>
      </NavLink>
    );
  };

  const menuItems = (items?: IMenuItem[], isBottomTabMenu?: boolean): any => {
    const menus = items?.length ? items : MENU_ITEMS;
    let allowedMenus = menus?.filter((val) =>
      hasPermission(val?.permissionKey),
    );
    if (isBottomTabMenu) {
      // To filter Bottom tab menus based on mobileTab attribute
      allowedMenus = allowedMenus.filter(
        (v) => v.mobileTab === isBottomTabMenu,
      );
    }

    return allowedMenus?.map((item: IMenuItem, idx: number) => {
      return {
        key: item?.path || `menu-item-${item.title}-${idx}`,
        icon:
          item?.badge?.count && (common.sideBarCollapsed || isBottomTabMenu) ? (
            <Badge
              {...item?.badge}
              size={'small'}
              offset={[-5, 12]}
              className={`${item?.badge?.count ? 'badge' : ''}`}
            >
              {item?.icon}
            </Badge>
          ) : (
            item?.icon
          ),
        label: renderNavigationLink(item),
        children: item?.children?.length
          ? menuItems(item?.children)
          : undefined,
      };
    });
  };

  return (
    <>
      {auth.authorized &&
        auth.userInfo?.managementEntity &&
        common.showMenuAndHeader && (
          <>
            <Layout.Sider
              collapsed={common.sideBarCollapsed}
              collapsedWidth={isMobileView ? 0 : 45}
              width={isMobileView ? 'calc(100% - 80px)' : 250}
              theme={'light'}
              className={`sideBar active-box-shadow`}
            >
              <Menu
                theme={'light'}
                defaultSelectedKeys={['/']}
                selectedKeys={[navLinkRef?.current || '']}
                mode={'inline'}
                items={menuItems()}
              />
            </Layout.Sider>
            {(common.mobileView || common.tabletView) &&
              !common.sideBarCollapsed && (
                <div
                  className={'sidebar-overlay'}
                  onClick={() => {
                    dispatch(CommonActions.toggleSideBar());
                  }}
                ></div>
              )}
            {isMobileView ? (
              <Menu
                theme={'light'}
                defaultSelectedKeys={['/']}
                selectedKeys={[navLinkRef?.current || '']}
                className={'bottom-tab-menus active-box-shadow'}
                items={menuItems(undefined, true)}
              />
            ) : null}
          </>
        )}
    </>
  );
};

export default React.memo(SideBar);
