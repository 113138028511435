import { ROLES, THEME } from 'store/types';

export const PERMISSIONS = {
  MENUS: {
    HOME: 'menu.home',
    RESELLER: 'menu.reseller',
    CHANNEL_PARTNER: 'menu.channelPartner',
    // DISTRIBUTOR: 'menu.distributor',
    ISP: 'menu.isp',
    FRANCHISEE: 'menu.franchisee',
    TRIALS: 'menu.trials',
    SUBSCRIBERS: 'menu.subscribers',
    SUBSCRIBER_GROUP: 'menu.subscriberGroup',
    OLT: 'menu.olt',
    DEVICES: 'menu.devices',
    BETA_GROUP: 'menu.betaGroup',
    USERS: 'menu.users',
    FIRMWARE: 'menu.firmware',
    ACTIONS: 'menu.actions',
    TRANSACTION_LOGS: 'menu.transactionLogs',
    BULK_ACTIONS: 'menu.bulkActions',
    OLT_SCHEDULE: 'menu.oltSchedule',
    PAYMENTS: 'menu.payments',
    TICKETS: 'menu.tickets',
    UNSUPPORTED_HARDWARE: 'menu.unsupportedHardware',
    OPERATORS: 'menu.operator',
  },
  CHANNEL_PARTNER: {
    DASHBOARD: 'channelPartner.dashboard',
    VIEW: 'channelPartner.view',
    CREATE: 'channelPartner.create',
    EDIT: 'channelPartner.edit',
    DELETE: 'channelPartner.delete',
  },
  RESELLER: {
    VIEW: 'reseller.view',
    CREATE: 'reseller.create',
    EDIT: 'reseller.edit',
    DELETE: 'reseller.delete',
    DASHBOARD: 'reseller.dashboard',
  },
  ISP: {
    DASHBOARD: 'isp.dashboard',
    VIEW: 'isp.view',
    CREATE: 'isp.create',
    EDIT: 'isp.edit',
    DELETE: 'isp.delete',
    ASSOCIATE_TO_DISTRIBUTOR: 'isp.associateToDistributor',
    SUBSCRIPTION_STATS: 'isp.subscriptionStats',
  },
  // DISTRIBUTOR: {
  //   DASHBOARD: 'distributor.dashboard',
  //   VIEW: 'distributor.view',
  //   CREATE: 'distributor.create',
  //   EDIT: 'distributor.edit',
  //   DELETE: 'distributor.delete',
  //   ASSOCIATE_AN_ISP: 'distributor.associateAnISP',
  // },
  FRANCHISEE: {
    DASHBOARD: 'franchisee.dashboard',
    VIEW: 'franchisee.view',
    CREATE: 'franchisee.create',
    EDIT: 'franchisee.edit',
    DELETE: 'franchisee.delete',
    SUBSCRIPTION_STATS: 'franchisee.subscriptionStats',
    FILTERS: {
      DIRECT_FRANCHISEE_ONLY: 'franchisee.filters.directFranchiseeOnly',
    },
  },
  TRIALS: {
    DASHBOARD: 'trials.dashboard',
    VIEW: 'trials.view',
    DELETE: 'trials.delete',
    SEND_APP_LINK: 'trials.sendAppLink',
    ACTIVATE: 'trials.activate',
    MARK_AS_COMPLETE: 'trials.markAsComplete',
    EXIT: 'trials.exit',
    MARK_AS_EXPIRED: 'trials.markAsExpired',
  },
  SUBSCRIBERS: {
    DASHBOARD: 'subscribers.dashboard',
    VIEW: 'subscribers.view',
    CREATE: 'subscribers.create',
    BULK_UPLOAD: 'subscribers.bulkUpload',
    DOWNLOAD_TEMPLATE: 'subscribers.downloadTemplate',
    EDIT: 'subscribers.edit',
    DELETE: 'subscribers.delete',
    SEARCH: 'subscribers.search',
    UPDATE_OLT_DETAILS: 'subscribers.updateOltDetails',
  },
  SUBSCRIBER_FILTERS: {
    MANAGEMENT_ENTITY: 'subscribers.filters.managementEntity',
    OPTICAL_SIGNAL_STRENGTH: 'subscribers.filter.opticalSignalStrength',
    SUBSCRIBER_GROUP: 'subscribers.filter.subscriberGroup',
    SUBSCRIPTION_STATE: 'subscribers.filter.subscriptionState',
    MULTIMODE: 'subscribers.filter.multiMode',
  },
  SUBSCRIBER_GROUP: {
    VIEW: 'subscriberGroup.view',
    CREATE: 'subscriberGroup.create',
    EDIT: 'subscriberGroup.edit',
    ADD_SUBSCRIBERS: 'subscriberGroup.addSubscribers',
    DROP_SUBSCRIBERS: 'subscriberGroup.dropSubscribers',
    DELETE: 'subscriberGroup.delete',
  },
  OLT: {
    CREATE: 'olt.create',
    EDIT: 'olt.edit',
    DELETE: 'olt.delete',
    SYNC_NOW: 'olt.syncNow',
    REACHABILITY_CHECK: 'olt.reachabilityCheck',
    GLOBAL_TELEMETRY_STATUS: 'olt.globalTelemetryStatus', // Platform Level (Global)
    DASHBOARD: {
      MAP_VIEW: 'olt.dashboard.mapView',
    },
  },
  OLT_FILTER: {
    ASSIGNED_TO: 'devices.filters.assignedTo',
  },
  SPLITTER: {
    VIEW: 'splitter.view',
    CREATE: 'splitter.create',
    EDIT: 'splitter.edit',
    DELETE: 'splitter.delete',
  },
  DEVICES: {
    DASHBOARD: 'devices.dashboard',
    VIEW: 'devices.view',
    BULK_ENROLLMENT: 'devices.bulkEnrollment',
    MARK_AS_ONBOARDING_COMPLETE: 'devices.markAsOnboardingComplete',
    DEVICE_BY_PROTOCOL_COUNT_STATS: 'devices.deviceByProtocolCountStats',
  },
  DEVICE_FILTERS: {
    MANAGEMENT_ENTITY: 'devices.filters.managementEntity',
    OLT: 'devices.filters.olt',
  },
  USERS: {
    LIST: 'users.list',
    VIEW: 'users.view',
    CREATE: 'users.create',
    EDIT: 'users.edit',
    DELETE: 'users.delete',
    FRANCHISEE_MULTI_SELECT: 'users.franchiseeMultiSelect',
  },

  ALARM: {
    CONFIGURATION: 'alarms.configuration',
  },

  OPERATORS: {
    VIEW: 'operators.view',
  },
  OPERATORS_FILTERS: {
    RESELLER: 'operators.filters.reseller',
  },
  FIRMWARE: {
    VIEW: 'firmware.view',
    UPLOAD_FIRMWARE: 'firmware.uploadFirmware',
    DOWNLOAD_IMAGE: 'firmware.downloadImage',
    MARK_AS_STABLE: 'firmware.markAsStable',
    PUBLISH: 'firmware.publish',
  },
  BULK_ACTIONS: {
    VIEW: 'bulkActions.view',
    CREATE: 'bulkActions.create',
    EDIT: 'bulkActions.edit',
    DELETE: 'bulkActions.delete',
  },
  OLT_SCHEDULE: {
    VIEW: 'oltSchedule.view',
    CREATE: 'oltSchedule.create',
    EDIT: 'oltSchedule.edit',
    DELETE: 'oltSchedule.delete',
  },
  PAYMENTS: {
    VIEW: 'payment.view',
    DOWNLOAD_RECEIPT: 'payment.downloadReceipt',
    PAY: 'payment.pay',
  },
  SUBSCRIPTIONS: {
    VIEW: 'subscriptions.view',
    UPDATE: 'subscriptions.update',
    SUSPEND_NOTIFY: 'subscriptions.suspendNotify', // Using SUSPEND_NOTIFY permission for both SUSPEND and EXPIRED subscription state
    EXTEND_SUBSCRIPTION: 'subscriptions.extendSubscription',
    MARK_AS_EXPIRED: 'subscriptions.markAsExpired',
  },
  TICKETS: {
    VIEW: 'tickets.view',
    EDIT: 'tickets.Edit',
    CREATE: 'tickets.create',
  },
  DASHBOARD_STATS: {
    //Common Stats
    DISTRIBUTORS_COUNT: 'dashboardStats.distributorCount',
    ISP_COUNT: 'dashboardStats.ispCount',
    FRANCHISEE_COUNT: 'dashboardStats.franchiseeCount',
    ACTION_STATS: 'dashboardStats.actionStats',

    // Onboarding Stats
    PENDING_SUBSCRIBERS: 'dashboardStats.pendingSubscribers', // Pending Configured pie chart
    SUBSCRIBER_ONBOARDING_TREND: 'dashboardStats.subscriberOnboardingTrend',
    TOP_PERFORMING_OPERATOR: 'dashboardStats.topPerformingOperator',
  },
  CONTACT_US: {
    ADMIN_CONTACT: 'contactUs.adminContact',
  },
  OPERATORS_SEARCH: 'operators.search',
  SMS_TRANSACTIONS: {
    // SMS - Subscriber Management System
    VIEW: 'smsTransactions.view',
    CLOSE_TICKET: 'smsTransactions.closeTicket',
  },

  SUBSCRIPTION_BASED_FEATURES: {
    ONT_MONITORING: {
      KEY: 'subscriptionCategories.ontMonitoring',
      FEATURES: {
        ONLINE_OFFLINE_STATUS:
          'subscriptionCategories.ontMonitoring.features.onlineOfflineStatus',
        PON_ACCESS_TYPE:
          'subscriptionCategories.ontMonitoring.features.ponAccessType',
        UPTIME: 'subscriptionCategories.ontMonitoring.features.uptime',
        CPU: 'subscriptionCategories.ontMonitoring.features.cpu',
        MEMORY: 'subscriptionCategories.ontMonitoring.features.memory',
        CLIENT_DEVICES:
          'subscriptionCategories.ontMonitoring.features.clientDevices',
        RADIO_INFORMATION:
          'subscriptionCategories.ontMonitoring.features.radioInformation',
        NETWORK_INFORMATION:
          'subscriptionCategories.ontMonitoring.features.networkInformation',
        ETHERNET_PORT_STATUS:
          'subscriptionCategories.ontMonitoring.features.ethernetPortStatus',
        OPTICAL_STRENGTH:
          'subscriptionCategories.ontMonitoring.features.opticalStrength',
        WAN_THROUGHPUT:
          'subscriptionCategories.ontMonitoring.features.wanThroughput',
        SYSTEM_EVENT_AND_LOGS:
          'subscriptionCategories.ontMonitoring.features.systemEventAndLogs',
      },
    },
    ONT_REMOTE_TROUBLESHOOTING: {
      KEY: 'subscriptionCategories.ontRemoteTroubleshooting',
      FEATURES: {
        LAN_SETTINGS:
          'subscriptionCategories.ontRemoteTroubleshooting.features.lanSettings',
        PING: 'subscriptionCategories.ontRemoteTroubleshooting.features.ping',
        TRACEROUTE:
          'subscriptionCategories.ontRemoteTroubleshooting.features.traceroute',
        SPEEDTEST:
          'subscriptionCategories.ontRemoteTroubleshooting.features.speedtest',
        REBOOT:
          'subscriptionCategories.ontRemoteTroubleshooting.features.reboot',
      },
    },
    ONT_WIFI_MANAGEMENT: {
      KEY: 'subscriptionCategories.ontWifiManagement',
      FEATURES: {
        PAUSE_PLAY:
          'subscriptionCategories.ontWifiManagement.features.pausePlay',
        SSID_PASSWORD:
          'subscriptionCategories.ontWifiManagement.features.ssidPassword',
        SCAN_AND_WIFI_OPTIMIZATION:
          'subscriptionCategories.ontWifiManagement.features.scanAndWifiOptimization',
        CHANNEL_SWITCH:
          'subscriptionCategories.ontWifiManagement.features.channelSwitch',
        CHANNEL_WIDTH:
          'subscriptionCategories.ontWifiManagement.features.channelWidth',
        WIFI_THROUGHPUT:
          'subscriptionCategories.ontWifiManagement.features.wifiThroughput',
        CLIENT_RSSI_STATISTICS:
          'subscriptionCategories.ontWifiManagement.features.clientRssiStatistics',
        CLIENT_THROUGHPUT:
          'subscriptionCategories.ontWifiManagement.features.clientThroughput',
      },
    },
    COMPLETE_OPTICAL_NETWORK_OBSERVABILITY: {
      KEY: 'subscriptionCategories.completeOpticalNetworkObservability',
      FEATURES: {
        BRIDGE_ONU_SUPPORT:
          'subscriptionCategories.completeOpticalNetworkObservability.features.bridgeOnuSupport',
        OPTICAL_STRENGTH:
          'subscriptionCategories.completeOpticalNetworkObservability.features.opticalStrength',
        REBOOT:
          'subscriptionCategories.completeOpticalNetworkObservability.features.reboot',
        RTT: 'subscriptionCategories.completeOpticalNetworkObservability.features.rtt',
      },
    },
    REAL_TIME_ALERTS: {
      KEY: 'subscriptionCategories.realTimeAlerts',
      FEATURES: {
        CUSTOM_THRESHOLD_CONFIGURATION:
          'subscriptionCategories.realTimeAlerts.features.customThresholdConfiguration',
        LOS_ALERTS: 'subscriptionCategories.realTimeAlerts.features.losAlerts',
      },
    },
    REPORTS: {
      KEY: 'subscriptionCategories.reports',
      FEATURES: {
        REPORTS_PENDING_SUBSCRIBERS:
          'subscriptionCategories.reports.features.pendingSubscribers',
        REPORTS_OFFLINE_SUBSCRIBERS:
          'subscriptionCategories.reports.features.offlineSubscribers',
        REPORTS_OLT_OPTICAL_SIGNAL:
          'subscriptionCategories.reports.features.oltOpticalSignal',
        REPORTS_ONT_OPTICAL_SIGNAL:
          'subscriptionCategories.reports.features.ontOpticalSignal',
        REPORTS_DATA_USAGE: 'subscriptionCategories.reports.features.dataUsage',
      },
    },
    OLT_MONITORING: {
      KEY: 'subscriptionCategories.oltMonitoring',
      FEATURES: {
        OLT_INFORMATION:
          'subscriptionCategories.oltMonitoring.features.oltInformation',
      },
    },

    ZERO_TOUCH_PROVISIONING: {
      KEY: 'subscriptionCategories.zeroTouchProvisioning',
      FEATURES: {
        ZERO_TOUCH_PROVISIONING:
          'subscriptionCategories.zeroTouchProvisioning.features.zeroTouchProvisioning',
      },
    },
  },
};

const getAllKeysInObject = (obj: any, excludeKeys: string[] = []): any =>
  Object.keys(obj).reduce((res: any, el: any) => {
    // To handle Nested objects
    if (
      typeof obj[el] === 'object' &&
      obj[el] !== null &&
      !excludeKeys?.includes(obj[el])
    ) {
      return [...res, ...getAllKeysInObject(obj[el], excludeKeys)];
    }
    if (!excludeKeys?.includes(obj[el])) {
      return [...res, obj[el]];
    } else {
      return [...res];
    }
  }, []);

export const AllowedPermission: { [key in ROLES]: string[] } = {
  [ROLES.PLATFORM_ADMIN]: [
    PERMISSIONS.MENUS.RESELLER,
    PERMISSIONS.MENUS.CHANNEL_PARTNER,
    // PERMISSIONS.MENUS.DISTRIBUTOR,
    PERMISSIONS.MENUS.ISP,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.TRIALS,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.BETA_GROUP,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.FIRMWARE,
    PERMISSIONS.MENUS.ACTIONS,
    PERMISSIONS.MENUS.TRANSACTION_LOGS,
    PERMISSIONS.MENUS.BULK_ACTIONS,
    PERMISSIONS.MENUS.OLT_SCHEDULE,
    PERMISSIONS.MENUS.PAYMENTS,
    PERMISSIONS.MENUS.UNSUPPORTED_HARDWARE,
    PERMISSIONS.MENUS.DEVICES,
    PERMISSIONS.MENUS.OPERATORS,
    ...Object.values(PERMISSIONS.RESELLER),
    ...Object.values(PERMISSIONS.CHANNEL_PARTNER),
    // ...Object.values(PERMISSIONS.DISTRIBUTOR),
    // ...Object.values(PERMISSIONS.ISP),
    // ...Object.values(PERMISSIONS.FRANCHISEE),
    ...getAllKeysInObject(PERMISSIONS.ISP),
    ...getAllKeysInObject(PERMISSIONS.FRANCHISEE),
    ...getAllKeysInObject(PERMISSIONS.TRIALS, [PERMISSIONS.TRIALS.EXIT]),
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS, [
      PERMISSIONS.SUBSCRIBERS.CREATE,
      PERMISSIONS.SUBSCRIBERS.BULK_UPLOAD,
    ]),
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBER_FILTERS, [
      PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    ]),
    PERMISSIONS.OLT.CREATE,
    PERMISSIONS.OLT.GLOBAL_TELEMETRY_STATUS,
    ...Object.values(PERMISSIONS.OPERATORS),
    ...Object.values(PERMISSIONS.OPERATORS_FILTERS),
    ...getAllKeysInObject(PERMISSIONS.USERS, [
      PERMISSIONS.USERS.FRANCHISEE_MULTI_SELECT,
    ]),
    ...Object.values(PERMISSIONS.FIRMWARE),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    PERMISSIONS.OLT_SCHEDULE.VIEW,
    PERMISSIONS.PAYMENTS.VIEW,
    PERMISSIONS.PAYMENTS.DOWNLOAD_RECEIPT,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTIONS, [
      PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    ]),
    ...Object.values(PERMISSIONS.DASHBOARD_STATS),
    ...Object.values(PERMISSIONS.DEVICE_FILTERS),
    PERMISSIONS.OPERATORS_SEARCH,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES, [
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.KEY,
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.FEATURES
        .CUSTOM_THRESHOLD_CONFIGURATION,
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.FEATURES
        .LOS_ALERTS,
    ]),
  ],
  [ROLES.CP_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    // PERMISSIONS.MENUS.DISTRIBUTOR,
    PERMISSIONS.MENUS.ISP,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.TRIALS,
    PERMISSIONS.MENUS.BETA_GROUP,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.ACTIONS,
    PERMISSIONS.CHANNEL_PARTNER.DASHBOARD,
    PERMISSIONS.CHANNEL_PARTNER.VIEW,
    PERMISSIONS.CHANNEL_PARTNER.EDIT,
    // ...Object.values(PERMISSIONS.DISTRIBUTOR),
    ...getAllKeysInObject(PERMISSIONS.ISP),
    ...getAllKeysInObject(PERMISSIONS.FRANCHISEE),
    PERMISSIONS.TRIALS.DASHBOARD,
    PERMISSIONS.TRIALS.VIEW,
    PERMISSIONS.TRIALS.DELETE,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.ACTIVATE,
    PERMISSIONS.TRIALS.MARK_AS_COMPLETE,
    ...Object.values(PERMISSIONS.USERS),
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTIONS, [
      PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    ]),
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT,
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ACTION_STATS,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    PERMISSIONS.OPERATORS_SEARCH,
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REPORTS.KEY,
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REPORTS.FEATURES
      .REPORTS_PENDING_SUBSCRIBERS,
  ],
  [ROLES.SUPPORT_ENGINEER]: [
    PERMISSIONS.MENUS.HOME,
    // PERMISSIONS.MENUS.DISTRIBUTOR,
    PERMISSIONS.MENUS.ISP,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.TRIALS,
    PERMISSIONS.MENUS.BETA_GROUP,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.ACTIONS,
    PERMISSIONS.CHANNEL_PARTNER.DASHBOARD,
    PERMISSIONS.CHANNEL_PARTNER.VIEW,
    PERMISSIONS.CHANNEL_PARTNER.EDIT,
    // ...Object.values(PERMISSIONS.DISTRIBUTOR),
    // PERMISSIONS.ISP.ASSOCIATE_TO_DISTRIBUTOR,
    ...getAllKeysInObject(PERMISSIONS.ISP, [
      PERMISSIONS.ISP.ASSOCIATE_TO_DISTRIBUTOR,
      PERMISSIONS.ISP.SUBSCRIPTION_STATS,
    ]),
    ...getAllKeysInObject(PERMISSIONS.FRANCHISEE, [
      PERMISSIONS.FRANCHISEE.SUBSCRIPTION_STATS,
    ]),
    PERMISSIONS.TRIALS.DASHBOARD,
    PERMISSIONS.TRIALS.VIEW,
    PERMISSIONS.TRIALS.DELETE,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.ACTIVATE,
    PERMISSIONS.TRIALS.MARK_AS_COMPLETE,
    ...Object.values(PERMISSIONS.USERS),
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTIONS, [
      PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    ]),
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT,
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ACTION_STATS,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    PERMISSIONS.OPERATORS_SEARCH,
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REPORTS.KEY,
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REPORTS.FEATURES
      .REPORTS_PENDING_SUBSCRIBERS,
  ],
  [ROLES.RESELLER_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.TRIALS,
    PERMISSIONS.MENUS.OPERATORS,
    PERMISSIONS.RESELLER.DASHBOARD,
    PERMISSIONS.TRIALS.DASHBOARD,
    PERMISSIONS.TRIALS.VIEW,
    PERMISSIONS.TRIALS.DELETE,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.ACTIVATE,
    PERMISSIONS.TRIALS.MARK_AS_COMPLETE,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTIONS, [
      PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    ]),
    ...Object.values(PERMISSIONS.OPERATORS),
  ],
  [ROLES.RESELLER_BILLING_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.TRIALS,
    PERMISSIONS.MENUS.OPERATORS,
    PERMISSIONS.RESELLER.DASHBOARD,
    PERMISSIONS.TRIALS.DASHBOARD,
    PERMISSIONS.TRIALS.VIEW,
    PERMISSIONS.TRIALS.DELETE,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.ACTIVATE,
    PERMISSIONS.TRIALS.MARK_AS_COMPLETE,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTIONS, [
      PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    ]),
    ...Object.values(PERMISSIONS.OPERATORS),
  ],
  [ROLES.SUPPORT_EXECUTIVE]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.TRIALS,
    PERMISSIONS.MENUS.OPERATORS,
    PERMISSIONS.RESELLER.DASHBOARD,
    PERMISSIONS.TRIALS.DASHBOARD,
    PERMISSIONS.TRIALS.VIEW,
    PERMISSIONS.TRIALS.DELETE,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.ACTIVATE,
    PERMISSIONS.TRIALS.MARK_AS_COMPLETE,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTIONS, [
      PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    ]),
    ...Object.values(PERMISSIONS.OPERATORS),
  ],
  [ROLES.DISTRIBUTOR_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.ISP,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.BETA_GROUP,
    PERMISSIONS.MENUS.USERS,
    // PERMISSIONS.DISTRIBUTOR.DASHBOARD,
    // PERMISSIONS.DISTRIBUTOR.VIEW,
    // PERMISSIONS.DISTRIBUTOR.EDIT,
    // PERMISSIONS.DISTRIBUTOR.ASSOCIATE_AN_ISP,
    PERMISSIONS.ISP.DASHBOARD,
    PERMISSIONS.ISP.VIEW,
    ...getAllKeysInObject(PERMISSIONS.FRANCHISEE, [
      PERMISSIONS.FRANCHISEE.SUBSCRIPTION_STATS,
      ...Object.values(PERMISSIONS.FRANCHISEE.FILTERS),
    ]),
    PERMISSIONS.SUBSCRIBERS.DOWNLOAD_TEMPLATE,
    ...Object.values(PERMISSIONS.USERS),
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT, // To show total distributors in ISP dashboard
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT,
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
  ],
  [ROLES.ISP_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    // PERMISSIONS.MENUS.DISTRIBUTOR,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.SUBSCRIBER_GROUP,
    PERMISSIONS.MENUS.OLT,
    PERMISSIONS.MENUS.DEVICES,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.BULK_ACTIONS,
    PERMISSIONS.MENUS.ACTIONS,
    // PERMISSIONS.DISTRIBUTOR.DASHBOARD,
    // PERMISSIONS.DISTRIBUTOR.VIEW,
    // PERMISSIONS.DISTRIBUTOR.CREATE,
    // PERMISSIONS.DISTRIBUTOR.EDIT,
    // PERMISSIONS.DISTRIBUTOR.DELETE,
    PERMISSIONS.ISP.DASHBOARD,
    PERMISSIONS.ISP.VIEW,
    PERMISSIONS.ISP.EDIT,
    // PERMISSIONS.ISP.ASSOCIATE_TO_DISTRIBUTOR,
    ...getAllKeysInObject(PERMISSIONS.FRANCHISEE, [
      PERMISSIONS.FRANCHISEE.SUBSCRIPTION_STATS,
      ...Object.values(PERMISSIONS.FRANCHISEE.FILTERS),
    ]),
    ...Object.values(PERMISSIONS.SUBSCRIBERS),
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBER_FILTERS, [
      PERMISSIONS.SUBSCRIBER_FILTERS.SUBSCRIPTION_STATE,
      PERMISSIONS.SUBSCRIBER_FILTERS.MULTIMODE,
    ]),
    ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
    ...getAllKeysInObject(PERMISSIONS.OLT, [
      PERMISSIONS.OLT.GLOBAL_TELEMETRY_STATUS,
      ...Object.values(PERMISSIONS.OLT.DASHBOARD),
    ]),
    PERMISSIONS.OLT_FILTER.ASSIGNED_TO,
    ...Object.values(PERMISSIONS.DEVICES),
    PERMISSIONS.DEVICE_FILTERS.OLT,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT, // Associated ISP shown in distributor dashboard
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    PERMISSIONS.DASHBOARD_STATS.ACTION_STATS,
    PERMISSIONS.OPERATORS_SEARCH,
    PERMISSIONS.ALARM.CONFIGURATION,
  ],
  [ROLES.TRIAL_ISP_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.SUBSCRIBER_GROUP,
    PERMISSIONS.MENUS.OLT,
    PERMISSIONS.MENUS.DEVICES,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.BULK_ACTIONS,
    PERMISSIONS.ISP.DASHBOARD,
    PERMISSIONS.ISP.VIEW,
    PERMISSIONS.ISP.EDIT,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.EXIT,
    ...Object.values(PERMISSIONS.SUBSCRIBERS),
    PERMISSIONS.SUBSCRIBER_FILTERS.MANAGEMENT_ENTITY,
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
    ...getAllKeysInObject(PERMISSIONS.OLT, [
      PERMISSIONS.OLT.GLOBAL_TELEMETRY_STATUS,
      ...Object.values(PERMISSIONS.OLT.DASHBOARD),
    ]),
    PERMISSIONS.OLT_FILTER.ASSIGNED_TO,
    PERMISSIONS.DEVICES.DASHBOARD,
    PERMISSIONS.DEVICES.VIEW,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    PERMISSIONS.PAYMENTS.PAY,
    PERMISSIONS.SUBSCRIPTIONS.VIEW,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    PERMISSIONS.CONTACT_US.ADMIN_CONTACT,
    PERMISSIONS.ALARM.CONFIGURATION,
  ],
  [ROLES.FRANCHISEE_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.OLT,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.FRANCHISEE.DASHBOARD,
    PERMISSIONS.FRANCHISEE.VIEW,
    PERMISSIONS.FRANCHISEE.EDIT,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS),
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    PERMISSIONS.OLT.SYNC_NOW,
    PERMISSIONS.OLT.REACHABILITY_CHECK,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
  ],
  [ROLES.TRIAL_FRANCHISEE_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.SUBSCRIBER_GROUP,
    PERMISSIONS.MENUS.OLT,
    PERMISSIONS.MENUS.DEVICES,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.BULK_ACTIONS,
    PERMISSIONS.FRANCHISEE.DASHBOARD,
    PERMISSIONS.FRANCHISEE.VIEW,
    PERMISSIONS.FRANCHISEE.EDIT,
    PERMISSIONS.TRIALS.SEND_APP_LINK,
    PERMISSIONS.TRIALS.EXIT,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS),
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
    ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
    ...getAllKeysInObject(PERMISSIONS.OLT, [
      PERMISSIONS.OLT.GLOBAL_TELEMETRY_STATUS,
      ...Object.values(PERMISSIONS.OLT.DASHBOARD),
    ]),
    PERMISSIONS.DEVICES.DASHBOARD,
    PERMISSIONS.DEVICES.VIEW,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    PERMISSIONS.PAYMENTS.PAY,
    PERMISSIONS.SUBSCRIPTIONS.VIEW,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    PERMISSIONS.CONTACT_US.ADMIN_CONTACT,
  ],
  [ROLES.BILLING_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.FRANCHISEE.DASHBOARD,
    PERMISSIONS.FRANCHISEE.VIEW,
    PERMISSIONS.FRANCHISEE.EDIT,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS),
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    ...Object.values(PERMISSIONS.PAYMENTS),
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
  ],
  [ROLES.ISP_BILLING_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    // PERMISSIONS.MENUS.DISTRIBUTOR,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.SUBSCRIBER_GROUP,
    PERMISSIONS.MENUS.OLT,
    PERMISSIONS.MENUS.DEVICES,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.MENUS.BULK_ACTIONS,
    PERMISSIONS.MENUS.PAYMENTS,
    PERMISSIONS.MENUS.ACTIONS,
    // PERMISSIONS.DISTRIBUTOR.DASHBOARD,
    // PERMISSIONS.DISTRIBUTOR.VIEW,
    // PERMISSIONS.DISTRIBUTOR.CREATE,
    // PERMISSIONS.DISTRIBUTOR.EDIT,
    // PERMISSIONS.DISTRIBUTOR.DELETE,
    PERMISSIONS.ISP.DASHBOARD,
    PERMISSIONS.ISP.VIEW,
    PERMISSIONS.ISP.EDIT,
    // PERMISSIONS.ISP.ASSOCIATE_TO_DISTRIBUTOR,
    ...getAllKeysInObject(PERMISSIONS.FRANCHISEE, [
      PERMISSIONS.FRANCHISEE.SUBSCRIPTION_STATS,
      ...Object.values(PERMISSIONS.FRANCHISEE.FILTERS),
    ]),
    ...Object.values(PERMISSIONS.SUBSCRIBERS),
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBER_FILTERS, [
      PERMISSIONS.SUBSCRIBER_FILTERS.SUBSCRIPTION_STATE,
      PERMISSIONS.SUBSCRIBER_FILTERS.MULTIMODE,
    ]),
    ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
    ...Object.values(PERMISSIONS.SUBSCRIBER_GROUP),
    ...getAllKeysInObject(PERMISSIONS.OLT, [
      PERMISSIONS.OLT.GLOBAL_TELEMETRY_STATUS,
      ...Object.values(PERMISSIONS.OLT.DASHBOARD),
    ]),
    PERMISSIONS.OLT_FILTER.ASSIGNED_TO,
    ...Object.values(PERMISSIONS.DEVICES),
    PERMISSIONS.DEVICE_FILTERS.OLT,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    ...Object.values(PERMISSIONS.PAYMENTS),
    PERMISSIONS.SUBSCRIPTIONS.VIEW,
    PERMISSIONS.SUBSCRIPTIONS.SUSPEND_NOTIFY,
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT, // Associated ISP shown in distributor dashboard
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    PERMISSIONS.DASHBOARD_STATS.ACTION_STATS,
    PERMISSIONS.OPERATORS_SEARCH,
    PERMISSIONS.ALARM.CONFIGURATION,
  ],
  [ROLES.FRANCHISEE_BILLING_ADMIN]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.MENUS.OLT,
    PERMISSIONS.MENUS.USERS,
    PERMISSIONS.FRANCHISEE.DASHBOARD,
    PERMISSIONS.FRANCHISEE.VIEW,
    PERMISSIONS.FRANCHISEE.EDIT,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS),
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    PERMISSIONS.OLT.SYNC_NOW,
    PERMISSIONS.OLT.REACHABILITY_CHECK,
    ...Object.values(PERMISSIONS.USERS),
    ...Object.values(PERMISSIONS.BULK_ACTIONS),
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
  ],
  [ROLES.FIELD_AGENT]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.SUBSCRIBERS.DASHBOARD,
    PERMISSIONS.SUBSCRIBERS.VIEW,
    PERMISSIONS.SUBSCRIBERS.SEARCH,
    PERMISSIONS.USERS.VIEW,
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT, // Associated ISP shown in distributor dashboard
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBER_FILTERS, [
      PERMISSIONS.SUBSCRIBER_FILTERS.SUBSCRIPTION_STATE,
      PERMISSIONS.SUBSCRIBER_FILTERS.MULTIMODE,
    ]),
    PERMISSIONS.OPERATORS_SEARCH,
  ],
  [ROLES.ISP_FIELD_AGENT]: [
    PERMISSIONS.MENUS.HOME,
    // PERMISSIONS.MENUS.DISTRIBUTOR,
    PERMISSIONS.MENUS.FRANCHISEE,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    // PERMISSIONS.DISTRIBUTOR.DASHBOARD,
    // PERMISSIONS.DISTRIBUTOR.VIEW,
    PERMISSIONS.ISP.DASHBOARD,
    PERMISSIONS.ISP.VIEW,
    PERMISSIONS.FRANCHISEE.DASHBOARD,
    PERMISSIONS.FRANCHISEE.VIEW,
    PERMISSIONS.SUBSCRIBERS.DASHBOARD,
    PERMISSIONS.SUBSCRIBERS.VIEW,
    PERMISSIONS.SUBSCRIBERS.SEARCH,
    PERMISSIONS.USERS.VIEW,
    PERMISSIONS.DASHBOARD_STATS.DISTRIBUTORS_COUNT,
    PERMISSIONS.DASHBOARD_STATS.ISP_COUNT, // Associated ISP shown in distributor dashboard
    PERMISSIONS.DASHBOARD_STATS.FRANCHISEE_COUNT,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBER_FILTERS, [
      PERMISSIONS.SUBSCRIBER_FILTERS.SUBSCRIPTION_STATE,
      PERMISSIONS.SUBSCRIBER_FILTERS.MULTIMODE,
    ]),
    PERMISSIONS.OPERATORS_SEARCH,
    PERMISSIONS.ALARM.CONFIGURATION,
  ],
  [ROLES.FRANCHISEE_FIELD_AGENT]: [
    PERMISSIONS.MENUS.HOME,
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.FRANCHISEE.DASHBOARD,
    PERMISSIONS.SUBSCRIBERS.DASHBOARD,
    PERMISSIONS.SUBSCRIBERS.VIEW,
    PERMISSIONS.SUBSCRIBERS.SEARCH,
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    PERMISSIONS.USERS.VIEW,
    PERMISSIONS.DASHBOARD_STATS.PENDING_SUBSCRIBERS,
    PERMISSIONS.DASHBOARD_STATS.SUBSCRIBER_ONBOARDING_TREND,
    PERMISSIONS.DASHBOARD_STATS.TOP_PERFORMING_OPERATOR,
  ],
  [ROLES.SUPPORT_AGENT]: [
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.SUBSCRIBERS.DASHBOARD,
    PERMISSIONS.SUBSCRIBERS.VIEW,
    PERMISSIONS.SUBSCRIBERS.SEARCH,
    PERMISSIONS.USERS.VIEW,
    PERMISSIONS.USERS.EDIT,
  ],
  [ROLES.SUBSCRIBER]: [],
  [ROLES.SUBSCRIBER_GROUP_ADMIN]: [
    PERMISSIONS.MENUS.SUBSCRIBERS,
    PERMISSIONS.SUBSCRIBERS.DASHBOARD,
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIBERS, [
      PERMISSIONS.SUBSCRIBERS.BULK_UPLOAD,
      PERMISSIONS.SUBSCRIBERS.CREATE,
      PERMISSIONS.SUBSCRIBERS.DELETE,
      PERMISSIONS.SUBSCRIBERS.DOWNLOAD_TEMPLATE,
      PERMISSIONS.SUBSCRIBERS.UPDATE_OLT_DETAILS,
    ]),
    PERMISSIONS.SUBSCRIBER_FILTERS.OPTICAL_SIGNAL_STRENGTH,
    PERMISSIONS.USERS.VIEW,
  ],
  [ROLES.FIRMWARE_MANAGEMENT]: [
    PERMISSIONS.MENUS.FIRMWARE,
    ...Object.values(PERMISSIONS.FIRMWARE),
    PERMISSIONS.USERS.VIEW,
  ],
};

export const ROLES_SUBSCRIPTION_MAPPING: { [key in ROLES]: string[] } = {
  [ROLES.PLATFORM_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.CP_ADMIN]: [],
  [ROLES.SUPPORT_ENGINEER]: [],
  [ROLES.RESELLER_ADMIN]: [],
  [ROLES.RESELLER_BILLING_ADMIN]: [],
  [ROLES.SUPPORT_EXECUTIVE]: [],
  [ROLES.DISTRIBUTOR_ADMIN]: [],
  [ROLES.ISP_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.TRIAL_ISP_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.FRANCHISEE_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.TRIAL_FRANCHISEE_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.BILLING_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.ISP_BILLING_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.FRANCHISEE_BILLING_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES),
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    ...getAllKeysInObject(PERMISSIONS.SPLITTER),
  ],
  [ROLES.FIELD_AGENT]: [
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES
        .COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.OLT_MONITORING,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_MONITORING,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_REMOTE_TROUBLESHOOTING,
      [
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_REMOTE_TROUBLESHOOTING
          .FEATURES.LAN_SETTINGS,
      ],
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT,
      [
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT.FEATURES
          .PAUSE_PLAY,
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT.FEATURES
          .SSID_PASSWORD,
      ],
    ),
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.KEY,
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    PERMISSIONS.SPLITTER.VIEW,
  ],
  [ROLES.ISP_FIELD_AGENT]: [
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES
        .COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.OLT_MONITORING,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_MONITORING,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_REMOTE_TROUBLESHOOTING,
      [
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_REMOTE_TROUBLESHOOTING
          .FEATURES.LAN_SETTINGS,
      ],
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT,
      [
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT.FEATURES
          .PAUSE_PLAY,
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT.FEATURES
          .SSID_PASSWORD,
      ],
    ),
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.KEY,
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    PERMISSIONS.SPLITTER.VIEW,
  ],
  [ROLES.FRANCHISEE_FIELD_AGENT]: [
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES
        .COMPLETE_OPTICAL_NETWORK_OBSERVABILITY,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.OLT_MONITORING,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_MONITORING,
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_REMOTE_TROUBLESHOOTING,
      [
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_REMOTE_TROUBLESHOOTING
          .FEATURES.LAN_SETTINGS,
      ],
    ),
    ...getAllKeysInObject(
      PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT,
      [
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT.FEATURES
          .PAUSE_PLAY,
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.ONT_WIFI_MANAGEMENT.FEATURES
          .SSID_PASSWORD,
      ],
    ),
    PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS.KEY,
    ...getAllKeysInObject(PERMISSIONS.OLT.DASHBOARD),
    PERMISSIONS.SPLITTER.VIEW,
  ],
  [ROLES.SUPPORT_AGENT]: [],
  [ROLES.SUBSCRIBER]: [],
  [ROLES.SUBSCRIBER_GROUP_ADMIN]: [
    ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES, [
      ...getAllKeysInObject(PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REPORTS),
      ...getAllKeysInObject(
        PERMISSIONS.SUBSCRIPTION_BASED_FEATURES.REAL_TIME_ALERTS,
      ),
    ]),
  ],
  [ROLES.FIRMWARE_MANAGEMENT]: [],
};

export const THEME_BASED_PERMISSIONS: {
  [key in THEME]?: { [key in ROLES]?: string[] };
} = {
  [THEME.DVOIS]: {
    [ROLES.PLATFORM_ADMIN]: [...Object.values(PERMISSIONS.SMS_TRANSACTIONS)],
    [ROLES.ISP_ADMIN]: [...Object.values(PERMISSIONS.SMS_TRANSACTIONS)],
    [ROLES.TRIAL_ISP_ADMIN]: [...Object.values(PERMISSIONS.SMS_TRANSACTIONS)],
    [ROLES.FRANCHISEE_ADMIN]: [...Object.values(PERMISSIONS.SMS_TRANSACTIONS)],
    [ROLES.TRIAL_FRANCHISEE_ADMIN]: [
      ...Object.values(PERMISSIONS.SMS_TRANSACTIONS),
    ],
    [ROLES.BILLING_ADMIN]: [...Object.values(PERMISSIONS.SMS_TRANSACTIONS)],
    [ROLES.ISP_BILLING_ADMIN]: [...Object.values(PERMISSIONS.SMS_TRANSACTIONS)],
    [ROLES.FRANCHISEE_BILLING_ADMIN]: [
      ...Object.values(PERMISSIONS.SMS_TRANSACTIONS),
    ],
    [ROLES.FIELD_AGENT]: [PERMISSIONS.SMS_TRANSACTIONS.VIEW],
    [ROLES.ISP_FIELD_AGENT]: [PERMISSIONS.SMS_TRANSACTIONS.VIEW],
    [ROLES.FRANCHISEE_FIELD_AGENT]: [PERMISSIONS.SMS_TRANSACTIONS.VIEW],
  },
};
