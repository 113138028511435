import { AlertProps, ButtonProps } from 'antd';
import {
  DURATION_UNIT,
  ISubscription,
  PACKAGE_NAME,
  SUBSCRIPTION_STATE,
} from './types/subscription.types';
import { IOLTBridgeStatus, PON_TYPE } from './types/olt.types';
import { Path, PathMatch } from 'react-router';

import { IAccounts } from './types/users.types';
import { IAlarmSummaryList } from './types/alarm.types';
import { IRange } from './types/subscriberDashboard.types';

//  #region - Auth
export interface IUserRoles {
  credId: string;
  credType: string;
  role: string;
  tenantName: string;
  userId: string;

  userStatus?: 'ACTIVE' | 'INACTIVE';
  deactivateeReason?: string;
}

export enum AUTH_STEPS {
  LOGIN = 'Login',
  ROLES = 'Roles',
  PASSWORD = 'Password',
  OTP = 'OTP',
}

export enum FORGOT_PASSWORD_STEPS {
  FORGOT_PASSWORD = 'ForgotPassword',
  ROLES = 'Roles',
  VERIFY_OTP = 'VerifyOTP',
  SET_PASSWORD = 'SetPassword',
}

export enum AUTH_CRED_TYPE {
  PASSWORD = 'ID_PASSWORD_CREDENTIAL',
  OTP = 'ID_OTP_CREDENTIAL',
}

export interface IRequestOTPResponseData {
  expiryTime: string;
  messageId: string;
  phoneNumber: string;
}
export interface AuthState {
  loading: boolean;
  authorized: boolean;
  mannualTrigger: boolean;
  returnTo: string;
  userInfo: IAuthUserInfo;
  homeLink: string | Partial<Path>;
  selectedLoginUserRole: IUserRoles;
  subscriptionStatus: SUBSCRIPTION_STATE;
  allowedPermissions: string[];
  trialDetails?: ITrialData | null;
}
export interface IAuthUserInfo {
  accounts: IAccounts[];
  id: string;
  role: ROLES;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  managementEntity: ManagementEntity;
  parentManagementEntity: ManagementEntity;
  supportNumber?: string[];
  friendly?: boolean;
  ticketingEnabled?: boolean;
  reseller?: IResellerData;
  rootUser: boolean;
  subscription: ISubscription;
  trialDetails?: ITrialData | null;
  allowAddressableCWMPConfig?: boolean;
  defaultSubscription?: boolean;
  allowFibermap?: boolean;
}
//  #endregion - Auth

export interface IChannelPartnersList {
  items: IChannelPartners[];
  pageSize: number;
  pageToken: number;
  total: number;
}

export interface ICallSupport {
  registrationType: 'SELF' | 'PARENT' | 'NONE';
  supportNumber: string[];
}
export interface ITrialConfig {
  duration: number;
  durationUnit: DURATION_UNIT;
  maxNumberOfSubscribers: number;
  extensionAllowed: boolean;
  maxExtensionAllowed: {
    duration: number;
    maxNumberOfSubscribers: number;
  };
}

export interface ILimit {
  maxNumberOfSubscribers: number;
}
export interface IManagementEntityTags {
  TRIAL: 'true' | 'false';
}
export interface IChannelPartners {
  id: string;
  name: string;
  displayName?: string;
  address: IAddress;
  adminContact: IAdminContact;
  companyContact: ICompanyContact;
  hardwareSupported?: string[];
  firmwarePolicy?: string;
  firmwareSchedule?: IFirmwareSchedule;
  sgId: string;
  callSupport: ICallSupport;
  friendly?: boolean;
}
export interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state?: string;
  country: string;
  pincode: string;
}
export interface IAdminContact {
  name: string;
  phoneNumber: string;
  email: string;
  password?: string;
}
export interface ICompanyContact {
  phoneNumber: string;
  email: string;
}
export interface IFirmwareSchedule {
  recurrence: string;
  time: string;
  overrideLevel: string;
}
export interface IFirmwarePolicies {
  id: string;
  name: string;
  displayName: string;
  description: string;
}

export enum MANAGEMENT_PROTOCOL {
  AGENT = 'AGENT',
  CWMP = 'CWMP', //'TR-069',
  OMCI = 'OMCI',
  MULTIMODE = 'AGENT|CWMP',
}
export enum MANAGEMENT_PROTOCOL_DISPLAY_NAME {
  AGENT = 'Agent',
  CWMP = 'TR-069',
  OMCI = 'OMCI',
  MULTIMODE = 'AGENT|CWMP',
  'AGENT|CWMP' = 'Multimode', // For Filter values display names
  OTHERS = 'Others',
}

//  #region - Trials
export const Trials_Tabs = {
  OVERVIEW: 'overview',
  LIST: 'list',
};
export enum TRIAL_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  EXITED = 'EXITED',
  EXTENDED = 'EXTENDED',
  MOVED_TO_PROD = 'MOVED_TO_PROD',
}
export enum TRIAL_STATUS_VIEW {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  EXITED = 'Exited',
  EXTENDED = 'Extended',
  MOVED_TO_PROD = 'Moved to Prod',
}
export interface ITrialList {
  items: ITrialData[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface ITrialData {
  id: string;
  managementEntity: {
    id: string;
    name: string;
  };
  adminUser: {
    id: string;
    emailId: string;
    phoneNumber: string;
    notificationToken?: string | null;
  };
  role: EntityType;
  subscriberCount?: {
    limit: number;
    onboarded: number;
  };
  startTime?: string | null;
  endTime?: string | null;
  status?: TRIAL_STATUS;
  extensions?: [];
  referredBy?: string | null;
  tags: {
    TRIAL?: 'true' | 'false';
  };
  reseller: IResellerData;
  supportContact: {
    name: string;
    phoneNumber: string;
  };
  subscription?: ISubscription;
  subscriberTemplateDownloadCount?: number;
}
export interface ITrialDashboardFilters {
  resellerId?: string | undefined | null;
}
export interface ITrialListFilters {
  status?: string | undefined | null;
  role?: string | undefined | null;
  resellerId?: string | undefined | null;
  duration?: string | undefined | null;
  fromDate?: string | undefined | null;
  toDate?: string | undefined | null;
  sort?: string;
}
export interface ITrialDurationList {
  key: string | number;
  label: string;
  value: string;
}
export interface ITrialsState {
  currentTabId: string;
  resellerList: {
    loading: boolean;
    error: string;
    data: IChannelPartners[];
  };
  trialDashboardFilters: ITrialDashboardFilters;
  overview: { loading: boolean; error: string; stats: ITrialStats };
  trialByAge: {
    loading: boolean;
    error: string;
    data: ITrialByAgeItems[];
  };
  trialList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: ITrialList;
  };
  trialListPagination: IPagination;
  trialListFilters: ITrialListFilters;
}
export interface ITrialStats {
  appUnused: number;
  trialConversionRate: IConversionRate;
  totalLeads: ITotalLeads;
  trialOnboardedStats: ITrialOnboardedStats;
  trialSubscribers: ITrialSubscribers;
}
export interface IConversionRate {
  exited: number;
  onboarded: number;
}
export interface ITotalLeads {
  franchisee: number;
  isp: number;
  total: number;
}
export interface ITrialOnboardedStats {
  pending: number;
  active: number;
  completed: number;
  extended: number;
  exited: number;
  movedToProd: number;
  total: number;
}
export interface ITrialSubscribers {
  onboarded: number;
  pending: number;
  total: number;
}
export interface ITrialByAge {
  items: ITrialByAgeItems[];
}
export interface ITrialByAgeItems {
  range: string;
  total: number;
  isp: number;
  franchisee: number;
  color?: string;
}
export interface ITrialNotesReponse {
  items: ITrialNote[];
  pageSize: number;
  pageToken: number;
  total?: number;
}
export interface ITrialNote {
  id: string;
  trialId: string;
  createdBy: {
    name: string;
    id: string;
    email: string;
    managementEntityId: string | null;
  };
  message: string;
  time: string;
}
//  #endregion - Trials
export interface ISubscriberSearchResult {
  subscriberId: string;
  subscriberName: string;
  phoneNumber: string;
  address: string;
  pincode: string;
  deviceIds: string[];
  managementEntities: ManagementEntity[];
  subscriberState: string;
}
export interface IOperatorSearchResult {
  id: string;
  type: EntityType;
  name: string;
  adminPhoneNumber: string;
  adminEmail: string;
  managementEntities: ManagementEntity[];
}

//  #region - Tickets
export interface ITicketsList {
  items: ITicket[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface ITicket {
  ticketId: string;
  createdBy: {
    userId: string;
    userName: string;
  };
  assignedTo: {
    userId: string;
    userName: string;
  };
  resolution: string;
  completedTime: string;
  createdTime: string;
  // updatedBy: string;
  subscriberName: string;
  subscriberId: string;
  phoneNumber: string;
  managementEntityId: string;
  managementEntities: ManagementEntity[];
  createdUsing: string;
  source?: string;
  category: string;
  description: string;
  comments?: ITicketComment[];
  status: TICKET_STATUS;
  priority: TICKET_PRIORITY;
  logs?: ITicketLog[];
}
export interface ITicketAssignee {
  id: string;
  name: string;
}
export interface ITicketSubscriber {
  id: string;
  name: string;
}
export interface ITicketComment {
  id: string;
  description: string;
  createTime: string;
  updatedAt: string;
  createdBy: {
    userId: string;
    userName: string;
  };
}

enum TicketLogEvent {
  STATUS_CHANGE = 'STATUS_CHANGE',
  COMMENT_UPDATE = 'COMMENT_UPDATE',
  ASSIGNEE_CHANGE = 'ASSIGNEE_CHANGE',
  PRIORITY_CHANGE = 'PRIORITY_CHANGE',
  CATEGORY_CHANGE = 'CATEGORY_CHANGE',
}
export interface ITicketLog {
  event: TicketLogEvent;
  data: ITicketLogData;
  userId: string;
  createTime: string;
}
export interface ITicketLogData {
  fromVal: string;
  toVal: string;
  commentId?: string;
  fieldName?: string;
}
export interface ITicketState {
  call: ICall;
  skippedCallIDs: string[];
}

export enum LIST_ACTIONS {
  VIEW_DETAILS = 'details',
  EDIT = 'edit',
  DELETE = 'delete',
  CREATE_ISP = 'create-isp',
  CREATE_FRANCHISEE = 'create-franchisee',
  VIEW_ALL_ISPS = 'view-all-isps',
  VIEW_ALL_FRANCHISEE = 'view-all-franchisee',
}

export enum TICKET_STATUS_VIEW {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  RESOLVED = 'Resolved',
  REJECTED = 'Rejected',
}

export enum TICKET_STATUS {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export enum TICKET_PRIORITY {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum TICKET_PRIORITY_VIEW {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum RESOLUTION {
  REMOTE = 'REMOTE',
  FIELD_VISIT = 'FIELD_VISIT',
  THIRD_PARTY_SOFTWARE = 'THIRD_PARTY_SOFTWARE',
}

export enum RESOLUTION_VIEW {
  REMOTE = 'Remote Troubleshooting',
  FIELD_VISIT = 'Field Visit',
  THIRD_PARTY_SOFTWARE = 'Third Party Software',
}
//  #endregion - Tickets
export interface ICall {
  userId: string;
  callSid: string;
  ticketId?: string;
  subscriberId: string;
  subscriberName: string;
  managementEntity: ManagementEntity;
  phoneNumber: string;
  error?: string;
  status: string;
}
export interface Action {
  payload: any;
}
export interface ManagementEntity {
  id: string;
  name: string;
  isParent?: boolean | null;
  type: EntityType;
}
export interface IOperatorStats {
  loading: boolean;
  unknownDevice: number;
  oltCount: number;
  unassignedDeviceCount: number;
  managementEntity: {
    franchisee: number;
    isp: number;
    channelPartner: number;
    // distributor: number;
  };
  error: boolean;
}
export interface OnlyLoading {
  loading: boolean;
}
export interface IManagementEntityDetails {
  id: string;
  type: EntityType | Reseller;
  name: string;
  displayName: string;
  address: IAddress;
  adminContact: IAdminContact;
  companyContact: ICompanyContact;
  channelPartnerId?: string;
  ispId?: string;
  hardwareSupported?: string[];
  firmwarePolicy?: string;
  sgId: string;
  friendly: boolean;
  managementEntities: ManagementEntity[];
  tags?: IManagementEntityTags;
  callSupport: ICallSupport;
  subscription?: ISubscription;

  managementEntityIds?: string[];
  createdBy?: string;
  trialConfig?: string;
  trialEnabled?: string;
  limit?: {
    maxNumberOfSubscribers: number;
  };
  crmSupported?: string;
  crmVendorName?: string;
  crmSubAccountsSupported?: string;
  apiCredentials?: string;
  isOnboardingCompleted?: boolean;
}
export enum EntityDepthLevel {
  FRANCHISEE = 1,
  DISTRIBUTOR = 2,
  ISP = 3,
  CHANNEL_PARTNER = 4,
  PLATFORM = 5,
}

export enum EntityDashboards {
  PLATFORM = '',
  CHANNEL_PARTNER = 'channel-partner',
  ISP = 'isp',
  DISTRIBUTOR = 'distributor',
  FRANCHISEE = 'franchisee',
}

export enum EntityType {
  PLATFORM = 'PLATFORM',
  CHANNEL_PARTNER = 'CHANNEL_PARTNER',
  ISP = 'ISP',
  DISTRIBUTOR = 'DISTRIBUTOR',
  FRANCHISEE = 'FRANCHISEE',
}

export enum Reseller {
  RESELLER = 'RESELLER',
}

export enum EntityTypeView {
  PLATFORM = 'Platform',
  CHANNEL_PARTNER = 'Channel Partner',
  ISP = 'ISP',
  DISTRIBUTOR = 'Distributor',
  FRANCHISEE = 'Franchisee',
}

export enum ROLES {
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',

  // Reseller
  RESELLER_ADMIN = 'RESELLER_ADMIN',
  RESELLER_BILLING_ADMIN = 'RESELLER_BILLING_ADMIN',
  SUPPORT_EXECUTIVE = 'SUPPORT_EXECUTIVE',

  // Channel Partner
  CP_ADMIN = 'CP_ADMIN',
  SUPPORT_ENGINEER = 'SUPPORT_ENGINEER', // Same roles as CP_ADMIN

  // Distributor
  DISTRIBUTOR_ADMIN = 'DISTRIBUTOR_ADMIN',

  // ISP
  ISP_ADMIN = 'ISP_ADMIN',
  TRIAL_ISP_ADMIN = 'TRIAL_ISP_ADMIN',

  // Franchisee
  FRANCHISEE_ADMIN = 'FRANCHISEE_ADMIN',
  TRIAL_FRANCHISEE_ADMIN = 'TRIAL_FRANCHISEE_ADMIN',

  // Common Roles for ISP and Franchisee
  BILLING_ADMIN = 'BILLING_ADMIN',
  FIELD_AGENT = 'FIELD_AGENT',
  SUBSCRIBER_GROUP_ADMIN = 'SUBSCRIBER_GROUP_ADMIN',
  FIRMWARE_MANAGEMENT = 'FIRMWARE_MANAGEMENT',

  // Temporary FIX for Field Agent and Billing Admin Role by Management Entity (ISP / Franchisee) - Only for UI purpose
  ISP_FIELD_AGENT = 'ISP_FIELD_AGENT',
  FRANCHISEE_FIELD_AGENT = 'FRANCHISEE_FIELD_AGENT',
  ISP_BILLING_ADMIN = 'ISP_BILLING_ADMIN',
  FRANCHISEE_BILLING_ADMIN = 'FRANCHISEE_BILLING_ADMIN',

  SUPPORT_AGENT = 'SUPPORT_AGENT',
  SUBSCRIBER = 'SUBSCRIBER',
}

export enum ROLES_VIEW {
  PLATFORM_ADMIN = 'Platform Admin',

  RESELLER_BILLING_ADMIN = 'Reseller Billing Admin',
  RESELLER_ADMIN = 'Reseller Admin',
  SUPPORT_EXECUTIVE = 'Support Executive',

  CP_ADMIN = 'CP Admin',
  SUPPORT_ENGINEER = 'Support Engineer',

  DISTRIBUTOR_ADMIN = 'Distributor Admin',

  ISP_ADMIN = 'ISP Admin',
  TRIAL_ISP_ADMIN = 'Trial ISP Admin',

  FRANCHISEE_ADMIN = 'Franchisee Admin',
  TRIAL_FRANCHISEE_ADMIN = 'Trial Franchisee Admin',

  BILLING_ADMIN = 'Billing Admin',
  FIELD_AGENT = 'Field Engineer',
  SUBSCRIBER_GROUP_ADMIN = 'Subscriber Group Admin',
  FIRMWARE_MANAGEMENT = 'Firmware Management',

  // Temporary Fix for Field Agent Role and Billing Role by Management Entity
  ISP_FIELD_AGENT = 'ISP Field Engineer',
  FRANCHISEE_FIELD_AGENT = 'Franchisee Field Engineer',
  ISP_BILLING_ADMIN = 'ISP Admin with Billing Access',
  FRANCHISEE_BILLING_ADMIN = 'Franchisee Admin with Billing Access',

  SUPPORT_AGENT = 'Support Agent',
  SUBSCRIBER = 'Subscriber',
}

export interface IResellerData {
  id: string;
  name: string;
}

//  #region - Unsupported Hardware
export interface IUnsupportedHardwareState {
  unsupportedHardwareList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IUnsupportedHardwareList;
  };
  unsupportedHardwareListPagination: IPagination;
}
export interface IUnsupportedHardwareList {
  items: IUnsupportedHardwareItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IUnsupportedHardwareItems {
  id: string;
  oui: string[];
  model: string;
  equipmentId: string;
  version: string;
  softwareVersion: string;
}
//  #endregion - Unsupported Hardware

export enum THEME {
  //  Theme value should be same as the tenant folder name.
  CYBRONET = 'cybronet',
  DATA_FLOW = 'dataFlow',
  DVOIS = 'dVois',
  FIBERX = 'fiberX',
  FIIANALYTICS = 'fiiAnalytics',
  GTPL = 'gtpl',
  JRCOMMUNICATION = 'jrCom',
  KUMAR_BBS = 'kumarBbs',
  NETLINK = 'netlink',
  NETLINK_BS = 'netlinkBS', //  Netlink Broadband Service
  NSB = 'nsb',
  PEERCAST = 'peerCast',
  READY_LINK = 'readyLink',
  REAL_FIBER = 'realFiber',
  SHINE = 'shine',
  WEONE = 'WeOne',
  LIVE_FIBER = 'livefibernet',
}
export interface CommonState {
  theme: THEME;
  tabTitle: string;
  sideBarCollapsed: boolean;
  mobileView: boolean;
  tabletView: boolean;
  betaWaning: boolean;
  landscape: boolean;
  offline: boolean;
  twa: boolean;
  versionCheckCompleted: boolean;
  appNotificationMessages: IAppNotifications[];
  showMenuAndHeader: boolean;
  logoClassName: string;
  oltBridgeStatus: { data: IOLTBridgeStatus };
  isOLTBridgeStatusUp: boolean;
}

export enum APP_NOTIFICATION_TYPES {
  ERROR = 'ERROR',
  WARNING = 'WARN',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  SYSTEM_MESSAGES = 'SYSTEM_MESSAGES',
  MAINTENANCE = 'MAINTENANCE',
  UPDATE_FIRMWARE = 'UPDATE_FIRMWARE',
}

export enum APP_NOTIFICATION_KEYS {
  MAINTENANCE = 'maintenance',
  SUBSCRIPTION_SUSPEND_EXPIRE = 'subscription_suspend_expire',
  OLT_BRIDGE_DOWN = 'olt_bridge-down',
  UPDATE_FIRMWARE = 'update-firmware',
  NETWORK_PREFERENCE_UPDATE = 'network-preference-update',
  UNMAPPED_SPLITTER_UPDATE = 'unmapped-splitter-update',
}
export interface IAppNotifications {
  key: APP_NOTIFICATION_KEYS;
  type: APP_NOTIFICATION_TYPES;
  showIcon?: boolean; // To show Notification Type Icon
  message: string | React.ReactNode;
  closable?: boolean;
  extraActions?: {
    key: string;
    buttonProps?: ButtonProps;
    buttonTitle?: string;
    navigateTo?: string;
    data?: any;
  }[];
}

export interface ICPOverview {
  id: string;
  name: string;
  displayName: string;
  address: IAddress;
  adminContact: {
    name: string;
    phoneNumber: string;
    email: string;
    // password?: string;
  };
  companyContact: {
    phoneNumber: string;
    email: string;
  };
  sgId: string;
  callSupport: ICallSupport;
  friendly?: boolean;
}
export interface CPDashboardState {
  overview: {
    loading: boolean;
    error: boolean;
    details: ICPOverview;
  };
  stats: {
    operator: IOperatorStats;
  };
  subscriberTrend: OnlyLoading;
  FWStats: OnlyLoading;
  HWStats: OnlyLoading;
  subscriberByPendingStats: OnlyLoading;
  operatorBySubscriberCount: OnlyLoading;
  filter: {
    id: string;
  };
  friendly?: boolean;
}

export interface IPagination {
  pageToken?: string | number;
  pageSize?: number;
  pageNumber?: number;
}
export interface IListSorting {
  sort?: string | null | undefined;
}
export type TLIST_SORT = 'ascend' | 'descend' | null;
export enum LIST_SORT {
  ascend = 'asc',
  descend = 'desc',
}
export enum OnlineStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum OnboardingState {
  LOCATION_MAPPED = 'LOCATION_MAPPED',
  CPE_SWAP_IN_PROGRESS = 'CPE_SWAP_IN_PROGRESS',
  PROVISIONED = 'PROVISIONED',
  CONFIGURED = 'CONFIGURED',
  PENDING = 'PENDING',
}

export enum CONFIGURATION_STATUS {
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  CONFIGURED = 'CONFIGURED',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export interface IAssociatedISP {
  id: string;
  name: string;
  displayName: string;
}

//  #region - Transaction Menu
export interface ITransactionLogs {
  hasNext: any;
  hasPrev: any;
  items: ITransactionItems[];
  page: number;
  pageId: any;
  pageSize: number;
}

export interface ITransactionItems {
  transactionId: string;
  activityId: string;
  data: ITransactiondata;
  endTime: string;
  initiator: ITransactionInitiator;
  sourceNode: ITransactionSourceNode;
  stages: ITransactionStages[];
  duration: number;
  startTime: string;
  status: string;
  target: any;
}
export interface ITransactiondata {
  some_key: string;
}

export interface ITransactionInitiator {
  id: string;
  orgId: string;
  type: string;
}
export interface ITransactionSourceNode {
  name: string;
  version: string;
  type: string;
}
export interface ITransactionStages {
  data: any;
  endTime: string;
  error: any;
  isSuccess: boolean;
  stageName: string;
  startTime: string;
  transactionId: string;
}
//#endregion - Transaction Menu

//  #region - Firmware
export interface IFirmware {
  band: 'SINGLE' | 'DUAL';
  bankerScheme: 'DUAL' | 'SINGLE';
  buildType: 'STABLE' | 'BETA';
  checksum: string;
  checksumType: string;
  createdTime: string;
  firmwareGroupId: string;
  firmwareGroupLabel: string;
  firmwareGroupName: string;
  firmwareId: string;
  firmwareVersion: string;
  id: string;
  installableId: string;
  installableMode: string;
  installableType: 'FIICORE_LOADED' | 'FIICORE_LITE_LOADED' | 'LAUNCHER';
  platform: string;
  s3Url: string;
  socType: string;
  state: InstallableState;
  updateMethod: string;
  updatedTime: string;
  url: string;
  version: string;
}

export enum FirmwareStates {
  UPLOADED = 'UPLOADED',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  MARKED_STABLE = 'MARKED_STABLE',
  READY_TO_PUBLISH = 'READY_TO_PUBLISH',
  SCHEDULED_FOR_PUBLISH = 'SCHEDULED_FOR_PUBLISH',
  PUBLISHED = 'PUBLISHED',
  PROCESSED = 'PROCESSED',
  OBSOLETE = 'OBSOLETE',
}

export enum FirmwareStatesView {
  UPLOADED = 'Uploaded',
  VERIFICATION_PENDING = 'Verification Pending',
  VERIFIED = 'Verified',
  REJECTED = 'Rejected',
  MARKED_STABLE = 'Marked Stable',
  READY_TO_PUBLISH = 'Ready to Publish',
  SCHEDULED_FOR_PUBLISH = 'Scheduled for Publish',
  PUBLISHED = 'Published',
  PROCESSED = 'Processed',
  OBSOLETE = 'Obsolete',
}
export interface FirmwareGroup {
  id: string;
  label: string;
  name: string;
  model: string;
}
export interface ManufacturerDetails {
  id: string;
  label: string;
  name: string;
  oui: string[];
  model: string[];
  firmwareGroup: FirmwareGroup[];
}
export interface FirmwareInfo {
  version: string;
  time: string;
  state?: InstallableState;
  installableId?: string;
}
export type FirmwareStatus =
  | 'INITIATED'
  | 'DOWNLOAD_SUCCESS'
  | 'DOWNLOAD_FAILED'
  | 'UPDATE_SUCCESS'
  | 'UPDATE_FAILED'
  | 'VALIDATE_SUCCESS';

export type InstallableState =
  | 'UPLOADED'
  | 'VERIFICATION_PENDING'
  | 'VERIFIED'
  | 'REJECTED'
  | 'MARKED_STABLE'
  | 'READY_TO_PUBLISH'
  | 'SCHEDULED_FOR_PUBLISH'
  | 'PUBLISHED'
  | 'PROCESSED'
  | 'OBSOLETE';

//  #endregion - Firmware

//  #region - Actions
export enum ACTION_STATUS_VIEW {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
}
export enum ACTION_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
export interface IActionListState {
  actionList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IActionList;
  };
  actionListPagination: IPagination;
  actionListFilters: IActionsListFilters;
  actionListSorting: IListSorting;
  actionListPathName: PathMatch<string> | null;
  isFilterOpen: boolean;
  actionsListFilterDropDownList: {
    managementEntityList?: IManagementEntityDetails[];
    actionsList?: IActionDropdownList[];
  };
}
export interface IActionDropdownList {
  name: string;
  displayName: string;
  value: string;
}
export interface IActionsListFilters {
  managementEntityId?: string;
  mac?: string;
  status?: string;
  app?: string;
  action?: string;
  duration?: string;
  dateRange?: any[];
  fromTime?: string;
  toTime?: string;
  managementProtocol?: string;

  hardwareVersion?: string;
  hardwareModel?: string;
  firmwareVersion?: string;
  launcherVersion?: string;
  agentVersion?: string;
  initiatedBy?: string;

  subscriberId?: string | null; // Used in Subscriber Dashboard Configuration tab
}
export interface IActionList {
  items: IActionItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IActionItems {
  transactionId: string;
  action: string;
  status: string;
  request: IResponse;
  response: IResponse;
  device: IActionItemDevice;
  fcmToken: string;
  app: string;
  initiatedBy: string;
  updatedTime: string;
  createdTime: string;
  managementEntities: ManagementEntity[];
  subscriber: IActionItemSubscriber;
  managementProtocol: string;
  userDetails: IActionUserDetails;
}
export interface IActionUserDetails {
  email: string;
  id: string;
  role: ROLES;
  name: string;
  managedBy: {
    name: string;
    type: EntityType;
  };
}
export interface IActionItemDevice {
  mac: string;
  serialNumber: string;
  dll: string;
  hardwareVersion: string;
  hardwareModel: string;
  firmwareVersion: string;
  agentVersion: string;
  launcherVersion: string;
}
export interface IResponse {
  time: string;
  payload: string;
}
export interface IActionItemSubscriber {
  id: string;
  name: string;
}
//  #endregion - Actions

export enum FrequencyBand {
  _2_4GHZ = '2.4 GHz',
  _5GHZ = '5.0 GHz',
}
//  #region - Schedule
export enum ActionType {
  IMMEDIATE = 'IMMEDIATE',
  SCHEDULED = 'SCHEDULED',
  RECURRING = 'RECURRING',
}
export enum ACTION_TYPE_VIEW {
  IMMEDIATE = 'Immediate',
  SCHEDULED = 'Scheduled',
  RECURRING = 'Recurring',
}
export enum SCHEDULE_ACTION_STATUS {
  DELETED = 'DELETED',
  FAILURE = 'FAILURE',
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
}
export enum Day {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}
export enum DisplayDay {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}
export enum Recurrence {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_WEEK = 'EVERY_WEEK',
}
export enum TargetedSubscriber {
  ALL_SUBSCRIBERS = 'ALL_SUBSCRIBERS',
  ONLY_MINE = 'ONLY_MINE',
  SELECTED_FRANCHISEE = 'SELECTED_FRANCHISEE',
}
//  #region - ONT Bulk Actions
export interface ICommandGroupState {
  commandGroupUpcomingList: {
    hardRefresh: boolean;
    loading: boolean;
    error: string;
    data: ICommandGroups;
  };
  commandGroupHistoryList: {
    hardRefresh: boolean;
    loading: boolean;
    error: string;
    data: ICommandGroups;
  };
  commandGroupUpcomingFilters: ICommandGroupFilters;
  commandGroupHistoryFilters: ICommandGroupFilters;
}
export interface ICommandGroupFilters {
  pageToken: number;
  pageSize: number;
}
export interface ICommandGroups {
  items: ICommandGroupItem[];
  total: number;
  pageSize: number;
  page: number;
}
export interface ICommandGroupItem {
  transactionId: string;
  action: string;
  actionType: string;
  scheduledAt: IScheduledAt;
  targetGroup: ITargetGroup;
  targetedSubscribers: string;
  franchisee: any[];
  franchiseeIds: string;
  hardware: IHardware[];
  triggeredTime: string;
  status: string;
  hardwareModels: string[];
  hardwareVersions: string[];
  manufacturers: string[];
  bands: string[];
}
export interface IHardware {
  id: string;
  displayModel: string;
  version: string;
  manufacturer: string;
}
export interface IScheduledAt {
  date: null;
  time: string;
  day: string;
  recurrence: string;
}
export interface ITargetGroup {
  id: string;
  name: string;
  type: string;
}
//  #endregion - ONT Bulk Actions

//  #region - OLT Schedule

export const OLT_Schedule_Tabs = {
  LIST: 'list',
  HISTORY: 'history',
};
export interface IOLTScheduleReduxState {
  oltScheduleList: {
    hardReload: boolean;
    loading: boolean;
    error: string;
    data: IOLTScheduleList;
  };
  oltScheduleListPagination: IPagination;
  oltScheduleHistoryList: {
    hardReload: boolean;
    loading: boolean;
    error: string;
    data: IOLTScheduleHistoryList;
  };
  oltScheduleHistoryListPagination: IPagination;
  oltScheduleHistoryListSorting: IListSorting;
  currentTabId: string;
}
export enum OLT_SCHEDULE_ACTIONS {
  OLT_DISCOVERY = 'OLT_DISCOVERY',
}
export enum OLT_SCHEDULE_ACTIONS_VIEW {
  OLT_DISCOVERY = 'OLT Discovery',
}
export interface IOLTScheduleList {
  items: IOLTScheduleItem[];
  total: number;
  pageSize: number;
  pageToken: number;
}
export interface IOLTScheduleItem {
  transactionId: string;
  action: OLT_SCHEDULE_ACTIONS;
  actionType: ActionType;
  scheduledAt: {
    time: string;
    date: string;
    day: string;
    recurrence: string;
  };
  displayScheduledAt?: string;
  scheduledBy: ManagementEntity;
  status: SCHEDULE_ACTION_STATUS;
  olts: IOLTS[];
}
export interface IOLTS {
  ipAddress: string;
  label?: string;
  mac: string;
  name?: string | null;
  hwVersion?: string | null;
  manufacturer?: string | null;
  swVersion?: string | null;
  ponPortCount?: string | null;
  ponType?: string | null;
}
export interface IOLTScheduleHistoryList {
  items: IOLTScheduleHistoryItem[];
  total: number;
  pageSize: number;
  pageToken: number;
}
export interface IOLTScheduleHistoryItem {
  transactionId: string;
  action: OLT_SCHEDULE_ACTIONS;
  actionType: ActionType;
  mac: string;
  ipAddress: string;
  name: string;
  label: string;
  manufacturer?: string;
  hwVersion?: string;
  swVersion?: string;
  ponType: PON_TYPE;
  initiatedTime: string;
  startedTime?: string | null;
  completedTime: string;
}
//  #endregion - OLT Schedule

//  #endregion - Schedule

//  #region - Friendly Beta Group
export interface FriendleBetaList {
  betaCompleted: Boolean;
  totalBetaSubscribers: number;
  upcomingReleaseCount?: number;
  optimalBetaSize: number;
  hardwareStatistics: FriendlyBetaListHW[];
  inProgressHardwareStatistics: FriendlyBetaListHW[];
  overAllVersionStats: {
    pending: number;
    success: number;
    failed: number;
    unknown: number;
  };
}
export interface FriendlyBetaListHW {
  model: string;
  displayModel: string;
  version: string;
  currentFirmware?: FirmwareInfo;
  pendingBetaFirmware: FirmwareInfo | null;
  minBetaSize: number;
  versionStats?: {
    pending: number;
    success: number;
    failed: number;
  };
  subscribers: BeataHWSubscriber[];
}
export interface BeataHWSubscriber {
  id: string;
  name: string;
  phoneNumber: string;
  emailId: string;
  managementEntity: ManagementEntity;
  managementEntityId: string;
  agentFirmwareVersion: string;
  firmwareStatus: FirmwareStatus;
  baseFirmwareVersion: string;
}

export enum InstallableStateDesc {
  UPLOADED = 'Beta firmware has been uploaded to cloud. It will be pushed to devices at mid night.',
  VERIFICATION_PENDING = 'Beta firmware has been pushed to the devices and pending on acknowledgment from devices.',
  VERIFIED = 'Beta firmware has been pushed and updated on all the beta friendly devices.',
  REJECTED = 'Release has been rejected. Reason may be a failure in the beta test or internal testing',
  MARKED_STABLE = 'Beta firmware has passed the beta test and promoted as a stable release.',
  READY_TO_PUBLISH = 'Firmware is stable and ready to publish.',
  SCHEDULED_FOR_PUBLISH = 'Firmware is scheduled to be pushed to all devices at mid night.',
  PUBLISHED = 'Firmware pushed to all devices.',
  PROCESSED = 'Old firmware.',
  OBSOLETE = 'Redundant firmware, not considered for use',
}
//  #endregion - Friendly Beta Group

//  #region - Receipt
export interface IReceiptListSubscription {
  id: string;
  name: string;
  package: {
    id: string;
    name: PACKAGE_NAME;
  };
  endDate: string;
  price: number;
}
export interface IReceiptList {
  items: IReceiptItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IReceiptItems {
  receiptNumber: string;
  generatedTime: string;
  managementEntity: ManagementEntity;
  subscription: IReceiptListSubscription;
  receiptDownloadUrl: string;
}
export interface IReceiptME {
  id: string;
  name: string;
  type: string;
}
export interface IReceiptListState {
  receiptList: {
    hardRefresh: boolean;
    loading: boolean;
    error: boolean;
    data: IReceiptList;
    receiptListFilter: {
      pageToken: number;
      pageSize: number;
    };
  };
}
// #endregion - Receipt

//  #region - Operator (ISP / Franchisee) Dashboard Graphs
export interface IActionStats {
  value: string;
  action: string;
  pending: number;
  failed: number;
  success: number;
  total: number;
}
export interface ISubscriberByOfflineData {
  items: ISubscriberOffineStats[];
  pageSize: number;
  pageToken: string;
  total: number;
  totalConfiguredSubscribers: number;
}
export interface ISubscriberOffineStats {
  managementEntity: ManagementEntity;
  subscribers: {
    online: number;
    offline: number;
    totalConfigured: number;
  };
}
export interface ISubscriberByPoorOpticalStats {
  items: IPoorOpticalStats[];
  opticalSignalScale: {
    poor: IRange;
  };
  pageSize: number;
  pageToken: string;
  total: number;
  totalConfiguredSubscribers: number;
}
export interface IPoorOpticalStats {
  managementEntity: ManagementEntity;
  subscribers: {
    poor: number;
    totalConfigured: number;
  };
}
export interface ISubscriberByPendingStats {
  items: IPendingStatsItem[];
  pageSize: number;
  pageToken: string;
  total: number;
}
export interface IPendingStatsItem {
  managementEntity: ManagementEntity;
  subscribers: {
    pending: number;
    total: number;
  };
}
export interface ISubscriberCount {
  total: number;
  down: number;
  up: number;
  pending: number;
  configured: number;
  poorOpticalSignal: number;
  splitter?: ISplitterSubscriberCountStats;
}
export interface ISplitterSubscriberCountStats {
  total: number;
  mapped: number;
  unmapped: number;
}
export interface IDashboardStatsState {
  subscriber: {
    loading: boolean;
    error: boolean;
    data: ISubscriberCount;
  };
  alarmSummaryStats: {
    loading: boolean;
    error: string;
    data: IAlarmSummaryList[];
  };
  pendingFirmwareUpdateStats: {
    loading: boolean;
    details: IPendingFirmwareUpdateStats;
    error: string;
  };
  configurationPendingStats: {
    loading: boolean;
    error: string | null;
    data: IConfigurationPendingStats;
  };
  offlineReasonCodeStats: {
    loading: boolean;
    error: string;
    data: IOfflineReasonCodeStats;
  };
}
export interface IOfflineReasonCodeStats {
  poweredOff: number;
  los: number;
  losLinkDown: number;
  unknown: number;
  total: number;
}
export interface IOfflineReasonCodeByOLT {
  oltLabel: string;
  oltMac: string;
  poweredOff: number;
  losLinkDown: number;
  los: number;
  total: number;
  unknown: number;
}
export interface IConfigurationPendingStats {
  count: number;
  networkPreferencesUpdated: boolean;
}
export interface ISubscriberCountPieData {
  category: string;
  value: number;
  color: string;
}
export interface IPoorOpticalStatsByOLTData {
  items: IPoorOpticalStatsByOLT[];
  opticalSignalScale: {
    poor: IRange;
  };
  pageSize: number;
  pageToken: string;
  total: number;
  totalConfiguredSubscribers: number;
}
export interface IPoorOpticalStatsByOLT {
  oltDetails: {
    label: string;
    value: string;
    ponPortCount: number;
  };
  subscribers: {
    poor: number;
    totalConfigured: number;
  };
}
export interface IPendingFirmwareUpdateByHWModel {
  hardwareVersion: string;
  hardwareModel: string;
  manufacturer: string;
  count: number;
  color?: string;
  model: string;
}
export interface IPendingFirmwareUpdateStats {
  firmwareStatsByManufacturer: IPendingFirmwareUpdateByHWModel[];
  overallCount?: number;
}
//  #endregion - Operator (ISP / Franchisee) Dashboard Graphs
export interface IFiltersDropdown {
  label: string;
  name: string;
  options: IFiltersDropdownOptions[];
}
export interface IFiltersDropdownOptions {
  label: string;
  manufacturer: string;
  supportedBands: string[];
  supportedProtocols?: string[];
  value: string;
  supportedValues?: string[];
  supportedVersions?: string[];
  supportSoftwareVersions?: string[];
  supportedHardwareVersions?: string[];
  displayLabel?: string;
  ponPortCount?: number;
}
export interface IOptions {
  label: string;
  value: string;
  key?: string;
  name?: string;
  version?: string; //  Used for hardware version in Subscriber Form - Device details section
}
export enum FIRMWARE_UPGRADE_TYPE {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

// #region - Reseller
export interface IResellerList {
  items: IResellerItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IResellerItems {
  id: string;
  name: string;
  address: IAddress;
  phoneNumber: string;
  approvedProducts?: string[];
  operationalRegions: IOperationalRegions[];
  email: string;
  bankDetails?: IBankDetails;
  GSTIN?: string;
  countryCode?: string;
}
export interface IBankDetails {
  name: string;
  branch: string;
  accountNumber: string;
  IFSCCode: string;
}
export interface IOperationalRegions {
  state: string;
  country: string;
  city: string;
}
export interface IResellerState {
  resellerList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IResellerList;
  };
  resellerListPagination: IPagination;
}
export interface ILeadsSubscription {
  plan: string;
  state: string;
  endDate: string;
}
export interface ILeadsItems {
  managementEntity: {
    id: string;
    name: string;
    type: string;
    subscription: ILeadsSubscription;
    phoneNumber: string;
    isTrial: boolean;
  };
  subscribers: {
    pending: number;
    configured: number;
    swap: number;
    total: number;
  };
}
export interface ILeadsList {
  items: ILeadsItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface ILeadsStats {
  activeLeads: {
    total: number;
    franchisee: number;
    isp: number;
  };
  closedLeads: {
    total: number;
    won: number;
    lost: number;
  };
}
export interface IOperatorBySubscription {
  total: number;
  silver: number;
  gold: number;
  platinum: number;
}
export interface IPaidSubscriptionExpiry {
  range: string;
  silver: ISubscriptionOpratorCount;
  gold: ISubscriptionOpratorCount;
  platinum: ISubscriptionOpratorCount;
}
export interface ISubscriptionOpratorCount {
  total: number;
  isp: number;
  franchisee: number;
}
export interface ISubscriptionExpiredCount {
  suspended: ISubscriptionOpratorCount;
  expired: ISubscriptionOpratorCount;
}
export interface ITrialByStatus {
  total: number;
  active: number;
  pending: number;
}
export interface ILoginStats {
  trial: ILoginStatsCount;
  prod: ILoginStatsCount;
}
export interface ILoginStatsCount {
  isp: number;
  franchisee: number;
  total: number;
}
//  #endregion - Reseller

//  #region - Operators
export enum LAST_ACTIVITY_DURATION_DISPLAY {
  '7d' = 'More than 7 days',
  '15d' = 'More than 15 days',
  '30d' = 'More than 30 days',
}
export interface IOperatorState {
  operatorList: {
    loading: boolean;
    hardReload: boolean;
    error: string;
    data: IOperatorList;
  };
  operatorListPagination: IPagination;
  operatorListFilters: IOperatorListFilters;
  operatorListSort: string;
  isFilterOpen: boolean;
  operatorFilterDropDownList: {
    resellerList: IResellerItems[];
  };
}
export interface IOperatorList {
  items: IOperatorItems[];
  pageSize: number;
  pageToken: number;
  total: number;
}
export interface IOperatorItems {
  id: string;
  name: string;
  displayName: string;
  type: string;
  adminContact: IAdminContact;
  companyContact: ICompanyContact;
  address: IAddress;
  subscription: ISubscription;
  lastActivity: {
    time: string;
    user: {
      id: string;
      name: string;
      role: ROLES;
    };
    clientApp: string;
  };
  subscriberCount: {
    configured: number;
    pending: number;
  };
  tags: {
    TRIAL: string;
  };
}
export interface IOperatorListFilters {
  resellerId?: string;
  entityType?: string;
  trial?: string | boolean;
  package?: string;
  subscriptionFromDate?: string;
  subscriptionToDate?: string;
  activityDate?: any;
  subscriberFromCount?: string;
  subscriberToCount?: string;
  subscriptionExpiryRange?: any[];
  lastActivityRange?: any[];
  subscriberCountRange?: any[];
  subscriptionState?: string;
}

//  #endregion - Operators

export const SERVICE_MODES = {
  INTERNET: 'INTERNET',
  TR069: 'TR069',
  TR069_INTERNET: 'TR069_INTERNET',
  Other: 'Other',
  VOIP: 'VOIP',
};
export interface IManagementEnityIdPathParam {
  managementEntityId: string;
}
export interface IPayloadError {
  error: string;
}
export interface IAlertMessage {
  type?: AlertProps['type'];
  message: string;
  description?: string;
}
export enum BOOLEAN {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export const Subscriber_Tabs = {
  CONFIGURED: 'configured',
  PENDING: 'pending',
};

export type TRegion = 'NOVA' | 'OHIO' | 'QA' | 'PROD-APS1';
export const FII_APP_URL = {
  NOVA: 'https://nova.fiianalytics.co.in/',
  OHIO: 'https://ohio.fiianalytics.co.in/',
  QA: 'https://qa.fiianalytics.in/',
  'PROD-APS1': 'https://fiiviz.com/',
};

export const LOADING = 'loading';
export const ACTIONS_TIMEOUT_INTERVAL = 60000;
export const ACTIONS_MESSAGE_WAIT_TIMEOUT_INTERVAL = 10000;
export const SPEED_TEST_UNIT = 'Mbps';
export const PING_UNIT = 'ms';
export const SIGNAL_STRENGTH_UNIT = 'dBm';
export const CURRENT_UNIT = 'mA';
export const VOLTAGE_UNIT = 'V';
export const TEMPERATURE_CELCIUS_UNIT = '°C';
export const OLT_DISTANCE_UNIT = 'meters';
export const SUBSCRIPTION_DIFF_DAY = 5;
export const SEARCH_DEBOUNCE_TIME = 500; // in Milliseconds
export const PON_LABEL = 'PON';
export const VLAN_ID_MAX_VALUE = 4094;
export const FORM_ROW_GUTTER: [number, number] = [10, 10];
